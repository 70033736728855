*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme="light"] {
  color-scheme: light;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 89.824% .06192 275.75;
  --ac: 15.352% .0368 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 49.12% .3096 275.75;
  --s: 69.71% .329 342.55;
  --sc: 98.71% .0106 342.55;
  --a: 76.76% .184 183.61;
  --n: 32.1785% .02476 255.702;
  --nc: 89.4994% .011585 252.096;
  --b1: 100% 0 0;
  --b2: 96.1151% 0 0;
  --b3: 92.4169% .00108 197.138;
  --bc: 27.8078% .029596 256.848;
}

[data-theme="dark"] {
  color-scheme: dark;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 13.138% .0392 275.75;
  --sc: 14.96% .052 342.55;
  --ac: 14.902% .0334 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 65.69% .196 275.75;
  --s: 74.8% .26 342.55;
  --a: 74.51% .167 183.61;
  --n: 31.3815% .021108 254.139;
  --nc: 74.6477% .0216 264.436;
  --b1: 25.3267% .015896 252.418;
  --b2: 23.2607% .013807 253.101;
  --b3: 21.1484% .01165 254.088;
  --bc: 74.6477% .0216 264.436;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.alert {
  text-align: center;
  border-radius: var(--rounded-box, 1rem);
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --alert-bg: var(--fallback-b2, oklch(var(--b2) / 1));
  --alert-bg-mix: var(--fallback-b1, oklch(var(--b1) / 1));
  background-color: var(--alert-bg);
  grid-auto-flow: row;
  align-content: flex-start;
  place-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  display: grid;
}

@media (min-width: 640px) {
  .alert {
    text-align: start;
    grid-template-columns: auto minmax(auto, 1fr);
    grid-auto-flow: column;
    justify-items: start;
  }
}

.artboard {
  width: 100%;
}

.avatar.placeholder > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.badge {
  border-radius: var(--rounded-badge, 1.9rem);
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: 1.25rem;
  padding-left: .563rem;
  padding-right: .563rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

@media (hover: hover) {
  .link-hover:hover {
    text-decoration-line: underline;
  }

  .label a:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  }

  .menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
    --tw-bg-opacity: 1;
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    --tw-text-opacity: 1;
    color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  }

  .radio-primary:hover {
    --tw-border-opacity: 1;
    border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  }

  .radio-success:hover {
    --tw-border-opacity: 1;
    border-color: var(--fallback-su, oklch(var(--su) / var(--tw-border-opacity)));
  }

  .radio-warning:hover {
    --tw-border-opacity: 1;
    border-color: var(--fallback-wa, oklch(var(--wa) / var(--tw-border-opacity)));
  }

  .table tr.hover:hover, .table tr.hover:nth-child(2n):hover {
    --tw-bg-opacity: 1;
    background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  }

  .table-zebra tr.hover:hover, .table-zebra tr.hover:nth-child(2n):hover {
    --tw-bg-opacity: 1;
    background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
  }
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--rounded-btn, .5rem);
  border-color: #0000;
  border-color: oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity));
  text-align: center;
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
  background-color: oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  --tw-border-opacity: 1;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, opacity, box-shadow, transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled], .btn:disabled {
  pointer-events: none;
}

:where(.btninput[type="checkbox"]), :where(.btninput[type="radio"]) {
  -webkit-appearance: none;
  appearance: none;
  width: auto;
}

.btninput[type="checkbox"]:after, .btninput[type="radio"]:after {
  --tw-content: attr(aria-label);
  content: var(--tw-content);
}

.card {
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  display: flex;
  position: relative;
}

.card:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.card-body {
  padding: var(--padding-card, 2rem);
  flex-direction: column;
  flex: auto;
  gap: .5rem;
  display: flex;
}

.card-body :where(p) {
  flex-grow: 1;
}

.card-actions {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.card figure {
  justify-content: center;
  align-items: center;
  display: flex;
}

.card.image-full {
  display: grid;
}

.card.image-full:before {
  content: "";
  z-index: 10;
  border-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  opacity: .75;
  position: relative;
}

.card.image-full:before, .card.image-full > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.card.image-full > figure img {
  object-fit: cover;
  height: 100%;
}

.card.image-full > .card-body {
  z-index: 20;
  --tw-text-opacity: 1;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  position: relative;
}

.checkbox {
  --chkbg: var(--fallback-bc, oklch(var(--bc) / 1));
  --chkfg: var(--fallback-b1, oklch(var(--b1) / 1));
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  border-width: 1px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-border-opacity: .2;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.collapse:not(td):not(tr):not(colgroup) {
  visibility: visible;
}

.collapse {
  border-radius: var(--rounded-box, 1rem);
  grid-template-rows: auto 0fr;
  width: 100%;
  transition: grid-template-rows .2s;
  display: grid;
  position: relative;
  overflow: hidden;
}

.collapse-title, .collapse > input[type="checkbox"], .collapse > input[type="radio"], .collapse-content {
  grid-row-start: 1;
  grid-column-start: 1;
}

.collapse > input[type="checkbox"], .collapse > input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
}

.collapse-content {
  visibility: hidden;
  cursor: unset;
  grid-row-start: 2;
  grid-column-start: 1;
  min-height: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.collapse[open], .collapse-open, .collapse:focus:not(.collapse-close), .collapse:not(.collapse-close):has( > input[type="checkbox"]:checked), .collapse:not(.collapse-close):has( > input[type="radio"]:checked) {
  grid-template-rows: auto 1fr;
}

.collapse[open] > .collapse-content, .collapse-open > .collapse-content, .collapse:focus:not(.collapse-close) > .collapse-content, .collapse:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-content, .collapse:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-content {
  visibility: visible;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.divider {
  white-space: nowrap;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  height: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.divider:before, .divider:after {
  --tw-content: "";
  content: var(--tw-content);
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  flex-grow: 1;
  width: 100%;
  height: .125rem;
}

@media (hover: hover) {
  .btm-nav > .disabled:hover, .btm-nav > [disabled]:hover {
    pointer-events: none;
    --tw-border-opacity: 0;
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    --tw-bg-opacity: .1;
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
    --tw-text-opacity: .2;
  }

  .btn:hover {
    --tw-border-opacity: 1;
    border-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-border-opacity)));
    --tw-bg-opacity: 1;
    background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn:hover {
      background-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)) 90%, black);
      border-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity, 1)) 90%, black);
    }
  }

  @supports not (color: oklch(0% 0 0)) {
    .btn:hover {
      background-color: var(--btn-color, var(--fallback-b2));
      border-color: var(--btn-color, var(--fallback-b2));
    }
  }

  .btn.glass:hover {
    --glass-opacity: 25%;
    --glass-border-opacity: 15%;
  }

  .btn-outline.btn-primary:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-primary:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-secondary:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-secondary:hover {
      background-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-accent:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-accent:hover {
      background-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-warning:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-warning:hover {
      background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    }
  }

  .btn-disabled:hover, .btn[disabled]:hover, .btn:disabled:hover {
    --tw-border-opacity: 0;
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    --tw-bg-opacity: .2;
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
    --tw-text-opacity: .2;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btninput[type="checkbox"]:checked:hover, .btninput[type="radio"]:checked:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(.active, .btn):hover, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(.active, .btn):hover {
    cursor: pointer;
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  @supports (color: oklch(0% 0 0)) {
    :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(.active, .btn):hover, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(.active, .btn):hover {
      background-color: var(--fallback-bc, oklch(var(--bc) / .1));
    }
  }
}

.file-input {
  border-radius: var(--rounded-btn, .5rem);
  border-width: 1px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  flex-shrink: 1;
  height: 3rem;
  padding-inline-end: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
}

.file-input::file-selector-button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  --tw-border-opacity: 1;
  border-style: solid;
  border-color: var(--fallback-n, oklch(var(--n) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-inline-end: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.indicator {
  width: max-content;
  display: inline-flex;
  position: relative;
}

.indicator :where(.indicator-item) {
  z-index: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  white-space: nowrap;
  position: absolute;
}

.input {
  -webkit-appearance: none;
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  border-width: 1px;
  border-color: #0000;
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.input[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.input-md[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.input-sm[type="number"]::-webkit-inner-spin-button {
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-end: 0;
}

.join {
  border-radius: var(--rounded-btn, .5rem);
  align-items: stretch;
  display: inline-flex;
}

.join :where(.join-item), .join .join-item:not(:first-child):not(:last-child), .join :not(:first-child):not(:last-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.join .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .join-item {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.join .dropdown .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .dropdown .join-item {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}

.join :where(.join-item:first-child:not(:last-child)), .join :where(:first-child:not(:last-child) .join-item) {
  border-start-start-radius: inherit;
  border-end-start-radius: inherit;
}

.join .join-item:last-child:not(:first-child), .join :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.join :where(.join-item:last-child:not(:first-child)), .join :where(:last-child:not(:first-child) .join-item) {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}

@supports not selector(:has(*)) {
  :where(.join *) {
    border-radius: inherit;
  }
}

@supports selector(:has(*)) {
  :where(.join :has(.join-item)) {
    border-radius: inherit;
  }
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.link-hover {
  text-decoration-line: none;
}

.mask {
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.menu {
  flex-flow: column wrap;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.menu :where(li ul) {
  white-space: nowrap;
  margin-inline-start: 1rem;
  padding-inline-start: .5rem;
  position: relative;
}

.menu :where(li:not(.menu-title) > :not(ul, details, .menu-title, .btn)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  -webkit-user-select: none;
  user-select: none;
  grid-auto-columns: minmax(auto, max-content) auto max-content;
  grid-auto-flow: column;
  align-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: grid;
}

.menu li.disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  color: var(--fallback-bc, oklch(var(--bc) / .3));
}

.menu :where(li > .menu-dropdown:not(.menu-dropdown-show)) {
  display: none;
}

:where(.menu li) {
  flex-flow: column wrap;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
  position: relative;
}

:where(.menu li) .badge {
  justify-self: end;
}

.radio {
  --chkbg: var(--bc);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border-width: 1px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-border-opacity: .2;
  border-radius: 9999px;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.select {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  background-image: linear-gradient(45deg, #0000 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, #0000 50%);
  background-position: calc(100% - 20px) calc(1px + 50%), calc(100% - 16.1px) calc(1px + 50%);
  background-repeat: no-repeat;
  background-size: 4px 4px, 4px 4px;
  border-width: 1px;
  border-color: #0000;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-size: .875rem;
  line-height: 2;
  display: inline-flex;
}

.select[multiple] {
  height: auto;
}

.stats {
  border-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  display: inline-grid;
}

:where(.stats) {
  grid-auto-flow: column;
  overflow-x: auto;
}

.stat {
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-border-opacity: .1;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1rem;
  width: 100%;
  padding: 1rem 1.5rem;
  display: inline-grid;
}

.stat-title {
  white-space: nowrap;
  color: var(--fallback-bc, oklch(var(--bc) / .6));
  grid-column-start: 1;
}

.stat-value {
  white-space: nowrap;
  grid-column-start: 1;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 2.5rem;
}

.stat-desc {
  white-space: nowrap;
  color: var(--fallback-bc, oklch(var(--bc) / .6));
  grid-column-start: 1;
  font-size: .75rem;
  line-height: 1rem;
}

.table {
  border-radius: var(--rounded-box, 1rem);
  text-align: left;
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  position: relative;
}

.table :where(.table-pin-rows thead tr) {
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  position: sticky;
  top: 0;
}

.table :where(.table-pin-rows tfoot tr) {
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  position: sticky;
  bottom: 0;
}

.table :where(.table-pin-cols tr th) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  position: sticky;
  left: 0;
  right: 0;
}

.table-zebra tbody tr:nth-child(2n) :where(.table-pin-cols tr th) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
}

.textarea {
  border-radius: var(--rounded-btn, .5rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  border-width: 1px;
  border-color: #0000;
  flex-shrink: 1;
  min-height: 3rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 2;
}

.timeline {
  display: flex;
  position: relative;
}

:where(.timeline > li) {
  grid-template-rows: var(--timeline-row-start, minmax(0, 1fr)) auto var(--timeline-row-end, minmax(0, 1fr));
  grid-template-columns: var(--timeline-col-start, minmax(0, 1fr)) auto var(--timeline-col-end, minmax(0, 1fr));
  flex-shrink: 0;
  align-items: center;
  display: grid;
  position: relative;
}

.timeline > li > hr {
  border-width: 0;
  width: 100%;
}

:where(.timeline > li > hr):first-child {
  grid-row-start: 2;
  grid-column-start: 1;
}

:where(.timeline > li > hr):last-child {
  grid-area: 2 / 3 / auto / none;
}

.timeline-start {
  grid-area: 1 / 1 / 2 / 4;
  place-self: flex-end center;
  margin: .25rem;
}

.timeline-middle {
  grid-row-start: 2;
  grid-column-start: 2;
}

.timeline-end {
  grid-area: 3 / 1 / 4 / 4;
  place-self: flex-start center;
  margin: .25rem;
}

.toggle {
  --tglbg: var(--fallback-b1, oklch(var(--b1) / 1));
  --handleoffset: 1.5rem;
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
  --togglehandleborder: 0 0;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border-radius: var(--rounded-badge, 1.9rem);
  color: var(--fallback-bc, oklch(var(--bc) / .5));
  transition: background, box-shadow var(--animation-input, .2s) ease-out;
  box-shadow: var(--handleoffsetcalculator) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset, var(--togglehandleborder);
  background-color: currentColor;
  border-width: 1px;
  border-color: currentColor;
  flex-shrink: 0;
  width: 3rem;
  height: 1.5rem;
}

.badge-neutral {
  --tw-border-opacity: 1;
  border-color: var(--fallback-n, oklch(var(--n) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
}

.badge-primary {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.badge-info {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-in, oklch(var(--in) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-inc, oklch(var(--inc) / var(--tw-text-opacity)));
  border-color: #0000;
}

.badge-success {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-su, oklch(var(--su) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
  border-color: #0000;
}

.badge-warning {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-wa, oklch(var(--wa) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
  border-color: #0000;
}

.badge-error {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-er, oklch(var(--er) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
  border-color: #0000;
}

.badge-outline {
  --tw-border-opacity: .5;
  color: currentColor;
  background-color: #0000;
  border-color: currentColor;
}

.badge-outline.badge-neutral {
  --tw-text-opacity: 1;
  color: var(--fallback-n, oklch(var(--n) / var(--tw-text-opacity)));
}

.badge-outline.badge-primary {
  --tw-text-opacity: 1;
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
}

.badge-outline.badge-secondary {
  --tw-text-opacity: 1;
  color: var(--fallback-s, oklch(var(--s) / var(--tw-text-opacity)));
}

.badge-outline.badge-accent {
  --tw-text-opacity: 1;
  color: var(--fallback-a, oklch(var(--a) / var(--tw-text-opacity)));
}

.badge-outline.badge-info {
  --tw-text-opacity: 1;
  color: var(--fallback-in, oklch(var(--in) / var(--tw-text-opacity)));
}

.badge-outline.badge-success {
  --tw-text-opacity: 1;
  color: var(--fallback-su, oklch(var(--su) / var(--tw-text-opacity)));
}

.badge-outline.badge-warning {
  --tw-text-opacity: 1;
  color: var(--fallback-wa, oklch(var(--wa) / var(--tw-text-opacity)));
}

.badge-outline.badge-error {
  --tw-text-opacity: 1;
  color: var(--fallback-er, oklch(var(--er) / var(--tw-text-opacity)));
}

.btm-nav > .disabled, .btm-nav > [disabled] {
  pointer-events: none;
  --tw-border-opacity: 0;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .97));
  animation: ease-out button-pop;
}

@supports not (color: oklch(0% 0 0)) {
  .btn {
    background-color: var(--btn-color, var(--fallback-b2));
    border-color: var(--btn-color, var(--fallback-b2));
  }

  .btn-primary {
    --btn-color: var(--fallback-p);
  }

  .btn-secondary {
    --btn-color: var(--fallback-s);
  }

  .btn-accent {
    --btn-color: var(--fallback-a);
  }

  .btn-neutral {
    --btn-color: var(--fallback-n);
  }

  .btn-warning {
    --btn-color: var(--fallback-wa);
  }
}

@supports (color: color-mix(in oklab, black, black)) {
  .btn-active {
    background-color: color-mix(in oklab, oklch(var(--btn-color, var(--b3)) / var(--tw-bg-opacity, 1)) 90%, black);
    border-color: color-mix(in oklab, oklch(var(--btn-color, var(--b3)) / var(--tw-border-opacity, 1)) 90%, black);
  }

  .btn-outline.btn-primary.btn-active {
    background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
  }

  .btn-outline.btn-secondary.btn-active {
    background-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
  }

  .btn-outline.btn-accent.btn-active {
    background-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
  }

  .btn-outline.btn-success.btn-active {
    background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
  }

  .btn-outline.btn-info.btn-active {
    background-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
  }

  .btn-outline.btn-warning.btn-active {
    background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
  }

  .btn-outline.btn-error.btn-active {
    background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
  }
}

.btn:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.btn-primary {
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

@supports (color: oklch(0% 0 0)) {
  .btn-primary {
    --btn-color: var(--p);
  }

  .btn-secondary {
    --btn-color: var(--s);
  }

  .btn-accent {
    --btn-color: var(--a);
  }

  .btn-neutral {
    --btn-color: var(--n);
  }

  .btn-warning {
    --btn-color: var(--wa);
  }
}

.btn-secondary {
  --tw-text-opacity: 1;
  color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
  outline-color: var(--fallback-s, oklch(var(--s) / 1));
}

.btn-accent {
  --tw-text-opacity: 1;
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
  outline-color: var(--fallback-a, oklch(var(--a) / 1));
}

.btn-neutral {
  --tw-text-opacity: 1;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  outline-color: var(--fallback-n, oklch(var(--n) / 1));
}

.btn-warning {
  --tw-text-opacity: 1;
  color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
  outline-color: var(--fallback-wa, oklch(var(--wa) / 1));
}

.btn.glass {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: currentColor;
}

.btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn-ghost.btn-active {
  background-color: var(--fallback-bc, oklch(var(--bc) / .2));
  border-color: #0000;
}

.btn-link.btn-active {
  background-color: #0000;
  border-color: #0000;
  text-decoration-line: underline;
}

.btn-outline.btn-active {
  --tw-border-opacity: 1;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-b1, oklch(var(--b1) / var(--tw-text-opacity)));
}

.btn-outline.btn-primary {
  --tw-text-opacity: 1;
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
}

.btn-outline.btn-primary.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.btn-outline.btn-secondary {
  --tw-text-opacity: 1;
  color: var(--fallback-s, oklch(var(--s) / var(--tw-text-opacity)));
}

.btn-outline.btn-secondary.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
}

.btn-outline.btn-accent {
  --tw-text-opacity: 1;
  color: var(--fallback-a, oklch(var(--a) / var(--tw-text-opacity)));
}

.btn-outline.btn-accent.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
}

.btn-outline.btn-success.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
}

.btn-outline.btn-info.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-inc, oklch(var(--inc) / var(--tw-text-opacity)));
}

.btn-outline.btn-warning {
  --tw-text-opacity: 1;
  color: var(--fallback-wa, oklch(var(--wa) / var(--tw-text-opacity)));
}

.btn-outline.btn-warning.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
}

.btn-outline.btn-error.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
}

.btn.btn-disabled, .btn[disabled], .btn:disabled {
  --tw-border-opacity: 0;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .2;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.btninput[type="checkbox"]:checked, .btninput[type="radio"]:checked {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.btninput[type="checkbox"]:checked:focus-visible, .btninput[type="radio"]:checked:focus-visible {
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .98));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.card :where(figure:first-child) {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-end-radius: unset;
  border-end-start-radius: unset;
  overflow: hidden;
}

.card :where(figure:last-child) {
  border-start-start-radius: unset;
  border-start-end-radius: unset;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
  overflow: hidden;
}

.card:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.card.bordered {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
}

.card.compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-title {
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: flex;
}

.card.image-full :where(figure) {
  border-radius: inherit;
  overflow: hidden;
}

.checkbox:focus {
  box-shadow: none;
}

.checkbox:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.checkbox:checked, .checkbox[aria-checked="true"] {
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-color: var(--chkbg);
  background-image: linear-gradient(-45deg, transparent 65%, var(--chkbg) 65.99%), linear-gradient(45deg, transparent 75%, var(--chkbg) 75.99%), linear-gradient(-45deg, var(--chkbg) 40%, transparent 40.99%), linear-gradient(45deg, var(--chkbg) 30%, var(--chkfg) 30.99%, var(--chkfg) 40%, transparent 40.99%), linear-gradient(-45deg, var(--chkfg) 50%, var(--chkbg) 50.99%);
}

.checkbox:indeterminate {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(-90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(0deg, var(--chkbg) 43%, var(--chkfg) 43%, var(--chkfg) 57%, var(--chkbg) 57%);
}

.checkbox:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .2;
  border-color: #0000;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

details.collapse {
  width: 100%;
}

details.collapse summary {
  outline-offset: 2px;
  outline: 2px solid #0000;
  display: block;
  position: relative;
}

details.collapse summary::-webkit-details-marker {
  display: none;
}

.collapse:focus-visible, .collapse:has(.collapse-title:focus-visible), .collapse:has( > input[type="checkbox"]:focus-visible), .collapse:has( > input[type="radio"]:focus-visible) {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.collapse-arrow > .collapse-title:after {
  --tw-translate-y: -100%;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 1.9rem;
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  width: .5rem;
  height: .5rem;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  inset-inline-end: 1.4rem;
  box-shadow: 2px 2px;
}

.collapse-plus > .collapse-title:after {
  top: .9rem;
  content: "+";
  pointer-events: none;
  width: .5rem;
  height: .5rem;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  inset-inline-end: 1.4rem;
}

.collapse:not(.collapse-open):not(.collapse-close) > input[type="checkbox"], .collapse:not(.collapse-open):not(.collapse-close) > input[type="radio"]:not(:checked), .collapse:not(.collapse-open):not(.collapse-close) > .collapse-title {
  cursor: pointer;
}

.collapse:focus:not(.collapse-open):not(.collapse-close):not(.collapse[open]) > .collapse-title {
  cursor: unset;
}

.collapse-title {
  position: relative;
}

:where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  z-index: 1;
}

.collapse-title, :where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  padding: 1rem;
  width: 100%;
  min-height: 3.75rem;
  padding-inline-end: 3rem;
  transition: background-color .2s ease-out;
}

.collapse[open] > :where(.collapse-content), .collapse-open > :where(.collapse-content), .collapse:focus:not(.collapse-close) > :where(.collapse-content), .collapse:not(.collapse-close) > :where(input[type="checkbox"]:checked ~ .collapse-content), .collapse:not(.collapse-close) > :where(input[type="radio"]:checked ~ .collapse-content) {
  padding-bottom: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.collapse[open].collapse-arrow > .collapse-title:after, .collapse-open.collapse-arrow > .collapse-title:after, .collapse-arrow:focus:not(.collapse-close) > .collapse-title:after, .collapse-arrow:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-arrow:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-title:after {
  --tw-translate-y: -50%;
  --tw-rotate: 225deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.collapse[open].collapse-plus > .collapse-title:after, .collapse-open.collapse-plus > .collapse-title:after, .collapse-plus:focus:not(.collapse-close) > .collapse-title:after, .collapse-plus:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-plus:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-title:after {
  content: "−";
}

.divider:not(:empty) {
  gap: 1rem;
}

.file-input-bordered {
  --tw-border-opacity: .2;
}

.file-input:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.file-input-disabled, .file-input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  --tw-text-opacity: .2;
}

.file-input-disabled::placeholder, .file-input[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.file-input-disabled::file-selector-button {
  --tw-border-opacity: 0;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .2;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.file-input[disabled]::file-selector-button {
  --tw-border-opacity: 0;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .2;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.label-text {
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  font-size: .875rem;
  line-height: 1.25rem;
}

.input input {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  background-color: #0000;
}

.input input:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input-bordered {
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.input:focus, .input:focus-within {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.input-primary {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
}

.input-primary:focus, .input-primary:focus-within {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

.input-disabled, .input:disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / .4));
}

.input-disabled::placeholder, .input:disabled::placeholder, .input[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.input::-webkit-date-and-time-value {
  text-align: inherit;
}

.join > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: -1px;
}

.join-item:focus {
  isolation: isolate;
}

.link-primary {
  --tw-text-opacity: 1;
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
}

@supports (color: color-mix(in oklab,black,black)) {
  @media (hover: hover) {
    .link-primary:hover {
      color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 80%, black);
    }

    .link-accent:hover {
      color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 80%, black);
    }

    .link-neutral:hover {
      color: color-mix(in oklab, var(--fallback-n, oklch(var(--n) / 1)) 80%, black);
    }
  }
}

.link-accent {
  --tw-text-opacity: 1;
  color: var(--fallback-a, oklch(var(--a) / var(--tw-text-opacity)));
}

.link-neutral {
  --tw-text-opacity: 1;
  color: var(--fallback-n, oklch(var(--n) / var(--tw-text-opacity)));
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.loading {
  pointer-events: none;
  aspect-ratio: 1;
  background-color: currentColor;
  width: 1.5rem;
  display: inline-block;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' stroke='%23000'%3E%3Cstyle%3E@keyframes spinner_zKoa{to{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%25{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%25{stroke-dasharray:42 150;stroke-dashoffset:-16}95%25,to{stroke-dasharray:42 150;stroke-dashoffset:-59}}%3C/style%3E%3Cg style='transform-origin:center;animation:spinner_zKoa 2s linear infinite'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3' class='spinner_V8m1' style='stroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite'/%3E%3C/g%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' stroke='%23000'%3E%3Cstyle%3E@keyframes spinner_zKoa{to{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%25{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%25{stroke-dasharray:42 150;stroke-dashoffset:-16}95%25,to{stroke-dasharray:42 150;stroke-dashoffset:-59}}%3C/style%3E%3Cg style='transform-origin:center;animation:spinner_zKoa 2s linear infinite'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3' class='spinner_V8m1' style='stroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite'/%3E%3C/g%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.loading-dots {
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cstyle%3E@keyframes spinner_8HQG{0%25,57.14%25{animation-timing-function:cubic-bezier(.33,.66,.66,1);transform:translate(0)}28.57%25{animation-timing-function:cubic-bezier(.33,0,.66,.33);transform:translateY(-6px)}to{transform:translate(0)}}.spinner_qM83{animation:spinner_8HQG 1.05s infinite}%3C/style%3E%3Ccircle cx='4' cy='12' r='3' class='spinner_qM83'/%3E%3Ccircle cx='12' cy='12' r='3' class='spinner_qM83' style='animation-delay:.1s'/%3E%3Ccircle cx='20' cy='12' r='3' class='spinner_qM83' style='animation-delay:.2s'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cstyle%3E@keyframes spinner_8HQG{0%25,57.14%25{animation-timing-function:cubic-bezier(.33,.66,.66,1);transform:translate(0)}28.57%25{animation-timing-function:cubic-bezier(.33,0,.66,.33);transform:translateY(-6px)}to{transform:translate(0)}}.spinner_qM83{animation:spinner_8HQG 1.05s infinite}%3C/style%3E%3Ccircle cx='4' cy='12' r='3' class='spinner_qM83'/%3E%3Ccircle cx='12' cy='12' r='3' class='spinner_qM83' style='animation-delay:.1s'/%3E%3Ccircle cx='20' cy='12' r='3' class='spinner_qM83' style='animation-delay:.2s'/%3E%3C/svg%3E");
}

.loading-lg {
  width: 2.5rem;
}

:where(.menu li:empty) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .1;
  height: 1px;
  margin: .5rem 1rem;
}

.menu :where(li ul):before {
  bottom: .75rem;
  inset-inline-start: 0;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .1;
  content: "";
  width: 1px;
  position: absolute;
  top: .75rem;
}

.menu :where(li:not(.menu-title) > :not(ul, details, .menu-title, .btn)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: var(--rounded-btn, .5rem);
  text-align: start;
  text-wrap: balance;
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

:where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(summary, .active, .btn).focus, :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(summary, .active, .btn):focus, :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):is(summary):not(.active, .btn):focus-visible, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(summary, .active, .btn).focus, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(summary, .active, .btn):focus, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):is(summary):not(.active, .btn):focus-visible {
  cursor: pointer;
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
}

.menu :where(li > details > summary)::-webkit-details-marker {
  display: none;
}

.menu :where(li > details > summary):after, .menu :where(li > .menu-dropdown-toggle):after {
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  justify-self: end;
  width: .5rem;
  height: .5rem;
  margin-top: -.5rem;
  transition-property: transform, margin-top;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  transform: rotate(45deg);
  box-shadow: 2px 2px;
}

.menu :where(li > details[open] > summary):after, .menu :where(li > .menu-dropdown-toggle.menu-dropdown-show):after {
  margin-top: 0;
  transform: rotate(225deg);
}

.mockup-phone .display {
  border-radius: 40px;
  margin-top: -25px;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  direction: ltr;
  width: 24rem;
  height: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input:before {
  content: "";
  aspect-ratio: 1;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 2px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .75rem;
  position: absolute;
  top: 50%;
  left: .5rem;
}

.mockup-browser .mockup-browser-toolbar .input:after {
  content: "";
  --tw-translate-y: 25%;
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 1px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .5rem;
  position: absolute;
  top: 50%;
  left: 1.25rem;
}

@keyframes modal-pop {
  0% {
    opacity: 0;
  }
}

@keyframes progress-loading {
  50% {
    background-position-x: -115%;
  }
}

.radio:focus {
  box-shadow: none;
}

.radio:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.radio:checked, .radio[aria-checked="true"] {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  animation: radiomark var(--animation-input, .2s) ease-out;
  box-shadow: 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  background-image: none;
}

.radio-primary {
  --chkbg: var(--p);
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
}

.radio-primary:focus-visible {
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

.radio-primary:checked, .radio-primary[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.radio-success {
  --chkbg: var(--su);
  --tw-border-opacity: 1;
  border-color: var(--fallback-su, oklch(var(--su) / var(--tw-border-opacity)));
}

.radio-success:focus-visible {
  outline-color: var(--fallback-su, oklch(var(--su) / 1));
}

.radio-success:checked, .radio-success[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: var(--fallback-su, oklch(var(--su) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-su, oklch(var(--su) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
}

.radio-warning {
  --chkbg: var(--wa);
  --tw-border-opacity: 1;
  border-color: var(--fallback-wa, oklch(var(--wa) / var(--tw-border-opacity)));
}

.radio-warning:focus-visible {
  outline-color: var(--fallback-wa, oklch(var(--wa) / 1));
}

.radio-warning:checked, .radio-warning[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: var(--fallback-wa, oklch(var(--wa) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-wa, oklch(var(--wa) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
}

.radio:disabled {
  cursor: not-allowed;
  opacity: .2;
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.select-bordered {
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.select:focus {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.select-primary {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
}

.select-primary:focus {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

.select-disabled, .select:disabled, .select[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.select-disabled::placeholder, .select:disabled::placeholder, .select[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.select-multiple, .select[multiple], .select[size].select:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

[dir="rtl"] .select {
  background-position: 12px calc(1px + 50%), 16px calc(1px + 50%);
}

@keyframes skeleton {
  from {
    background-position: 150%;
  }

  to {
    background-position: -50%;
  }
}

:where(.stats) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

:is([dir="rtl"] .stats > :not([hidden]) ~ :not([hidden])) {
  --tw-divide-x-reverse: 1;
}

:is([dir="rtl"] .table) {
  text-align: right;
}

.table :where(th, td) {
  vertical-align: middle;
  padding: .75rem 1rem;
}

.table tr.active, .table tr.active:nth-child(2n), .table-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
}

.table-zebra tr.active, .table-zebra tr.active:nth-child(2n), .table-zebra-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
}

.table :where(thead, tbody) :where(tr:not(:last-child)), .table :where(thead, tbody) :where(tr:first-child:last-child) {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-bottom-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
}

.table :where(thead, tfoot) {
  white-space: nowrap;
  color: var(--fallback-bc, oklch(var(--bc) / .6));
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
}

.textarea-bordered {
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.textarea:focus {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.textarea-disabled, .textarea:disabled, .textarea[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  --tw-text-opacity: .2;
}

.textarea-disabled::placeholder, .textarea:disabled::placeholder, .textarea[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.timeline hr {
  height: .25rem;
}

:where(.timeline hr) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
}

:where(.timeline:has(.timeline-middle) hr):first-child {
  border-start-start-radius: 0;
  border-start-end-radius: var(--rounded-badge, 1.9rem);
  border-end-end-radius: var(--rounded-badge, 1.9rem);
  border-end-start-radius: 0;
}

:where(.timeline:has(.timeline-middle) hr):last-child, :where(.timeline:not(:has(.timeline-middle)) :first-child hr:last-child) {
  border-start-start-radius: var(--rounded-badge, 1.9rem);
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: var(--rounded-badge, 1.9rem);
}

:where(.timeline:not(:has(.timeline-middle)) :last-child hr:first-child) {
  border-start-start-radius: 0;
  border-start-end-radius: var(--rounded-badge, 1.9rem);
  border-end-end-radius: var(--rounded-badge, 1.9rem);
  border-end-start-radius: 0;
}

.timeline-box {
  border-radius: var(--rounded-box, 1rem);
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: .5rem 1rem;
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

[dir="rtl"] .toggle {
  --handleoffsetcalculator: calc(var(--handleoffset) * 1);
}

.toggle:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.toggle:hover {
  background-color: currentColor;
}

.toggle:checked, .toggle[aria-checked="true"] {
  --handleoffsetcalculator: var(--handleoffset);
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  background-image: none;
}

[dir="rtl"] .toggle:checked, [dir="rtl"] .toggle[aria-checked="true"] {
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
}

.toggle:indeterminate {
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

[dir="rtl"] .toggle:indeterminate {
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

.toggle:disabled {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  opacity: .3;
  --togglehandleborder: 0 0 0 3px var(--fallback-bc, oklch(var(--bc) / 1)) inset, var(--handleoffsetcalculator) 0 0 3px var(--fallback-bc, oklch(var(--bc) / 1)) inset;
  background-color: #0000;
}

.glass, .glass.btn-active {
  -webkit-backdrop-filter: blur(var(--glass-blur, 40px));
  backdrop-filter: blur(var(--glass-blur, 40px));
  background-color: #0000;
  background-image: linear-gradient(135deg, rgb(255 255 255 / var(--glass-opacity, 30%))0%, #0000 100%), linear-gradient(var(--glass-reflex-degree, 100deg), rgb(255 255 255 / var(--glass-reflex-opacity, 10%))25%, #0000 25%);
  box-shadow: 0 0 0 1px rgb(255 255 255 / var(--glass-border-opacity, 10%))inset, 0 0 0 2px #0000000d;
  text-shadow: 0 1px rgb(0 0 0 / var(--glass-text-shadow-opacity, 5%));
  border: none;
}

@media (hover: hover) {
  .glass.btn-active {
    -webkit-backdrop-filter: blur(var(--glass-blur, 40px));
    backdrop-filter: blur(var(--glass-blur, 40px));
    background-color: #0000;
    background-image: linear-gradient(135deg, rgb(255 255 255 / var(--glass-opacity, 30%))0%, #0000 100%), linear-gradient(var(--glass-reflex-degree, 100deg), rgb(255 255 255 / var(--glass-reflex-opacity, 10%))25%, #0000 25%);
    box-shadow: 0 0 0 1px rgb(255 255 255 / var(--glass-border-opacity, 10%))inset, 0 0 0 2px #0000000d;
    text-shadow: 0 1px rgb(0 0 0 / var(--glass-text-shadow-opacity, 5%));
    border: none;
  }
}

.artboard.phone {
  width: 320px;
}

.artboard.phone-1 {
  width: 320px;
  height: 568px;
}

.artboard.phone-1.horizontal, .artboard.phone-1.artboard-horizontal {
  width: 568px;
  height: 320px;
}

.artboard.phone-2 {
  width: 375px;
  height: 667px;
}

.artboard.phone-2.horizontal, .artboard.phone-2.artboard-horizontal {
  width: 667px;
  height: 375px;
}

.artboard.phone-3 {
  width: 414px;
  height: 736px;
}

.artboard.phone-3.horizontal, .artboard.phone-3.artboard-horizontal {
  width: 736px;
  height: 414px;
}

.artboard.phone-4 {
  width: 375px;
  height: 812px;
}

.artboard.phone-4.horizontal, .artboard.phone-4.artboard-horizontal {
  width: 812px;
  height: 375px;
}

.artboard.phone-5 {
  width: 414px;
  height: 896px;
}

.artboard.phone-5.horizontal, .artboard.phone-5.artboard-horizontal {
  width: 896px;
  height: 414px;
}

.artboard.phone-6 {
  width: 320px;
  height: 1024px;
}

.artboard.phone-6.horizontal, .artboard.phone-6.artboard-horizontal {
  width: 1024px;
  height: 320px;
}

.badge-xs {
  height: .75rem;
  padding-left: .313rem;
  padding-right: .313rem;
  font-size: .75rem;
  line-height: .75rem;
}

.badge-sm {
  height: 1rem;
  padding-left: .438rem;
  padding-right: .438rem;
  font-size: .75rem;
  line-height: 1rem;
}

.badge-lg {
  height: 1.5rem;
  padding-left: .688rem;
  padding-right: .688rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn-xs {
  height: 1.5rem;
  min-height: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .75rem;
}

.btn-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
}

.btn-square:where(.btn-xs) {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-square:where(.btn-sm) {
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-circle:where(.btn-xs) {
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-circle:where(.btn-sm) {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.file-input-sm {
  height: 2rem;
  padding-inline-end: .75rem;
  font-size: .875rem;
  line-height: 2;
}

.file-input-sm::file-selector-button {
  margin-right: .75rem;
  font-size: .875rem;
}

.indicator :where(.indicator-item) {
  bottom: auto;
  inset-inline: auto 0;
  --tw-translate-y: -50%;
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 0;
}

:is([dir="rtl"] .indicator :where(.indicator-item)) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.indicator :where(.indicator-item.indicator-start) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset-inline: 0 auto;
}

:is([dir="rtl"] .indicator :where(.indicator-item.indicator-start)) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.indicator :where(.indicator-item.indicator-center) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset-inline: 50%;
}

:is([dir="rtl"] .indicator :where(.indicator-item.indicator-center)) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.indicator :where(.indicator-item.indicator-end) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset-inline: auto 0;
}

:is([dir="rtl"] .indicator :where(.indicator-item.indicator-end)) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.indicator :where(.indicator-item.indicator-bottom) {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: auto;
  bottom: 0;
}

.indicator :where(.indicator-item.indicator-middle) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
  bottom: 50%;
}

.indicator :where(.indicator-item.indicator-top) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 0;
  bottom: auto;
}

.input-sm {
  height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
  line-height: 2rem;
}

.join.join-vertical {
  flex-direction: column;
}

.join.join-vertical .join-item:first-child:not(:last-child), .join.join-vertical :first-child:not(:last-child) .join-item {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.join.join-vertical .join-item:last-child:not(:first-child), .join.join-vertical :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
}

.join.join-horizontal {
  flex-direction: row;
}

.join.join-horizontal .join-item:first-child:not(:last-child), .join.join-horizontal :first-child:not(:last-child) .join-item {
  border-start-start-radius: inherit;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: inherit;
}

.join.join-horizontal .join-item:last-child:not(:first-child), .join.join-horizontal :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
  border-end-start-radius: 0;
}

[type="radio"].radio-sm {
  width: 1.25rem;
  height: 1.25rem;
}

.select-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: 2rem;
  font-size: .875rem;
  line-height: 2rem;
}

[dir="rtl"] .select-sm {
  padding-left: 2rem;
  padding-right: .75rem;
}

.select-xs {
  height: 1.5rem;
  min-height: 1.5rem;
  padding-left: .5rem;
  padding-right: 2rem;
  font-size: .75rem;
  line-height: 1.625;
}

[dir="rtl"] .select-xs {
  padding-left: 2rem;
  padding-right: .5rem;
}

.timeline-vertical {
  flex-direction: column;
}

.timeline-compact .timeline-start, .timeline-horizontal.timeline-compact .timeline-start {
  grid-area: 3 / 1 / 4 / 4;
  place-self: flex-start center;
  margin: .25rem;
}

.timeline-compact li:has(.timeline-start) .timeline-end, .timeline-horizontal.timeline-compact li:has(.timeline-start) .timeline-end {
  grid-row-start: auto;
  grid-column-start: none;
}

.timeline-vertical.timeline-compact > li {
  --timeline-col-start: 0;
}

.timeline-vertical.timeline-compact .timeline-start {
  grid-area: 1 / 3 / 4 / 4;
  place-self: center start;
}

.timeline-vertical.timeline-compact li:has(.timeline-start) .timeline-end {
  grid-row-start: none;
  grid-column-start: auto;
}

:where(.timeline-vertical > li) {
  --timeline-row-start: minmax(0, 1fr);
  --timeline-row-end: minmax(0, 1fr);
  justify-items: center;
}

.timeline-vertical > li > hr {
  height: 100%;
}

:where(.timeline-vertical > li > hr):first-child {
  grid-row-start: 1;
  grid-column-start: 2;
}

:where(.timeline-vertical > li > hr):last-child {
  grid-area: 3 / 2 / none;
}

.timeline-vertical .timeline-start {
  grid-area: 1 / 1 / 4 / 2;
  place-self: center end;
}

.timeline-vertical .timeline-end {
  grid-area: 1 / 3 / 4 / 4;
  place-self: center start;
}

.timeline-vertical:where(.timeline-snap-icon) > li {
  --timeline-col-start: minmax(0, 1fr);
  --timeline-row-start: .5rem;
}

.timeline-horizontal .timeline-start {
  grid-area: 1 / 1 / 2 / 4;
  place-self: flex-end center;
}

.timeline-horizontal .timeline-end {
  grid-area: 3 / 1 / 4 / 4;
  place-self: flex-start center;
}

[type="checkbox"].toggle-sm {
  --handleoffset: .75rem;
  width: 2rem;
  height: 1.25rem;
}

.tooltip {
  --tooltip-offset: calc(100% + 1px + var(--tooltip-tail, 0px));
  display: inline-block;
  position: relative;
}

.tooltip:before {
  pointer-events: none;
  z-index: 1;
  content: var(--tw-content);
  --tw-content: attr(data-tip);
  position: absolute;
}

.tooltip:before, .tooltip-top:before {
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-offset);
  transform: translateX(-50%);
}

.card-compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-compact .card-title {
  margin-bottom: .25rem;
}

.card-normal .card-body {
  padding: var(--padding-card, 2rem);
  font-size: 1rem;
  line-height: 1.5rem;
}

.card-normal .card-title {
  margin-bottom: .75rem;
}

.join.join-vertical > :where(:not(:first-child)) {
  margin-top: -1px;
  margin-left: 0;
  margin-right: 0;
}

.join.join-horizontal > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: -1px;
}

.table-xs :not(thead):not(tfoot) tr {
  font-size: .75rem;
  line-height: 1rem;
}

.table-xs :where(th, td) {
  padding: .25rem .5rem;
}

.timeline-vertical > li > hr {
  width: .25rem;
}

:where(.timeline-vertical:has(.timeline-middle) > li > hr):first-child {
  border-bottom-right-radius: var(--rounded-badge, 1.9rem);
  border-bottom-left-radius: var(--rounded-badge, 1.9rem);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

:where(.timeline-vertical:has(.timeline-middle) > li > hr):last-child, :where(.timeline-vertical:not(:has(.timeline-middle)) :first-child > hr:last-child) {
  border-top-left-radius: var(--rounded-badge, 1.9rem);
  border-top-right-radius: var(--rounded-badge, 1.9rem);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

:where(.timeline-vertical:not(:has(.timeline-middle)) :last-child > hr:first-child) {
  border-bottom-right-radius: var(--rounded-badge, 1.9rem);
  border-bottom-left-radius: var(--rounded-badge, 1.9rem);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

:where(.timeline-horizontal:has(.timeline-middle) > li > hr):first-child {
  border-start-start-radius: 0;
  border-start-end-radius: var(--rounded-badge, 1.9rem);
  border-end-end-radius: var(--rounded-badge, 1.9rem);
  border-end-start-radius: 0;
}

:where(.timeline-horizontal:has(.timeline-middle) > li > hr):last-child {
  border-start-start-radius: var(--rounded-badge, 1.9rem);
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: var(--rounded-badge, 1.9rem);
}

.tooltip {
  text-align: center;
  --tooltip-tail: .1875rem;
  --tooltip-color: var(--fallback-n, oklch(var(--n) / 1));
  --tooltip-text-color: var(--fallback-nc, oklch(var(--nc) / 1));
  --tooltip-tail-offset: calc(100% + .0625rem - var(--tooltip-tail));
  display: inline-block;
  position: relative;
}

.tooltip:before, .tooltip:after {
  opacity: 0;
  transition: color .2s cubic-bezier(.4, 0, .2, 1) .1s, background-color .2s cubic-bezier(.4, 0, .2, 1) .1s, border-color .2s cubic-bezier(.4, 0, .2, 1) .1s, text-decoration-color .2s cubic-bezier(.4, 0, .2, 1) .1s, fill .2s cubic-bezier(.4, 0, .2, 1) .1s, stroke .2s cubic-bezier(.4, 0, .2, 1) .1s, opacity .2s cubic-bezier(.4, 0, .2, 1) .1s, box-shadow .2s cubic-bezier(.4, 0, .2, 1) .1s, transform .2s cubic-bezier(.4, 0, .2, 1) .1s, filter .2s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s, backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s;
}

.tooltip:after {
  content: "";
  border-style: solid;
  border-width: var(--tooltip-tail, 0);
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.tooltip:before {
  background-color: var(--tooltip-color);
  color: var(--tooltip-text-color);
  border-radius: .25rem;
  width: max-content;
  max-width: 20rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.tooltip.tooltip-open:before, .tooltip.tooltip-open:after, .tooltip:hover:before, .tooltip:hover:after, .tooltip:has(:focus-visible):after, .tooltip:has(:focus-visible):before {
  opacity: 1;
  transition-delay: 75ms;
}

.tooltip:not([data-tip]):hover:before, .tooltip:not([data-tip]):hover:after {
  visibility: hidden;
  opacity: 0;
}

.tooltip:after, .tooltip-top:after {
  border-color: var(--tooltip-color) transparent transparent transparent;
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-tail-offset);
  transform: translateX(-50%);
}

.tooltip-primary {
  --tooltip-color: var(--fallback-p, oklch(var(--p) / 1));
  --tooltip-text-color: var(--fallback-pc, oklch(var(--pc) / 1));
}

.tooltip-info {
  --tooltip-color: var(--fallback-in, oklch(var(--in) / 1));
  --tooltip-text-color: var(--fallback-inc, oklch(var(--inc) / 1));
}

.tooltip-success {
  --tooltip-color: var(--fallback-su, oklch(var(--su) / 1));
  --tooltip-text-color: var(--fallback-suc, oklch(var(--suc) / 1));
}

.tooltip-warning {
  --tooltip-color: var(--fallback-wa, oklch(var(--wa) / 1));
  --tooltip-text-color: var(--fallback-wac, oklch(var(--wac) / 1));
}

.tooltip-error {
  --tooltip-color: var(--fallback-er, oklch(var(--er) / 1));
  --tooltip-text-color: var(--fallback-erc, oklch(var(--erc) / 1));
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.collapse {
  visibility: collapse;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.right-0 {
  right: 0;
}

.top-2 {
  top: .5rem;
}

.z-10 {
  z-index: 10;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.m-2 {
  margin: .5rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-24 {
  height: 6rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-\[400px\] {
  height: 400px;
}

.h-email {
  height: 1280px;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.min-h-\[50\%\] {
  min-height: 50%;
}

.min-h-\[70px\] {
  min-height: 70px;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-20 {
  width: 5rem;
}

.w-3\/4 {
  width: 75%;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-email {
  width: 800px;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-mobile-email {
  width: 450px;
}

.min-w-flow-chart {
  min-width: 1200px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.basis-14 {
  flex-basis: 3.5rem;
}

.basis-16 {
  flex-basis: 4rem;
}

.basis-52 {
  flex-basis: 13rem;
}

.table-auto {
  table-layout: auto;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.cursor-default {
  cursor: default;
}

.cursor-help {
  cursor: help;
}

.cursor-pointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-center {
  place-items: center;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-end {
  justify-items: end;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity));
}

.self-center {
  align-self: center;
}

.justify-self-start {
  justify-self: start;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hyphens-auto {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l {
  border-left-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dotted {
  border-style: dotted;
}

.border-amber-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-amber-100\/0 {
  border-color: #fef3c700;
}

.border-amber-100\/10 {
  border-color: #fef3c71a;
}

.border-amber-100\/100 {
  border-color: #fef3c7;
}

.border-amber-100\/15 {
  border-color: #fef3c726;
}

.border-amber-100\/20 {
  border-color: #fef3c733;
}

.border-amber-100\/25 {
  border-color: #fef3c740;
}

.border-amber-100\/30 {
  border-color: #fef3c74d;
}

.border-amber-100\/35 {
  border-color: #fef3c759;
}

.border-amber-100\/40 {
  border-color: #fef3c766;
}

.border-amber-100\/45 {
  border-color: #fef3c773;
}

.border-amber-100\/5 {
  border-color: #fef3c70d;
}

.border-amber-100\/50 {
  border-color: #fef3c780;
}

.border-amber-100\/55 {
  border-color: #fef3c78c;
}

.border-amber-100\/60 {
  border-color: #fef3c799;
}

.border-amber-100\/65 {
  border-color: #fef3c7a6;
}

.border-amber-100\/70 {
  border-color: #fef3c7b3;
}

.border-amber-100\/75 {
  border-color: #fef3c7bf;
}

.border-amber-100\/80 {
  border-color: #fef3c7cc;
}

.border-amber-100\/85 {
  border-color: #fef3c7d9;
}

.border-amber-100\/90 {
  border-color: #fef3c7e6;
}

.border-amber-100\/95 {
  border-color: #fef3c7f2;
}

.border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-amber-300\/0 {
  border-color: #fcd34d00;
}

.border-amber-300\/10 {
  border-color: #fcd34d1a;
}

.border-amber-300\/100 {
  border-color: #fcd34d;
}

.border-amber-300\/15 {
  border-color: #fcd34d26;
}

.border-amber-300\/20 {
  border-color: #fcd34d33;
}

.border-amber-300\/25 {
  border-color: #fcd34d40;
}

.border-amber-300\/30 {
  border-color: #fcd34d4d;
}

.border-amber-300\/35 {
  border-color: #fcd34d59;
}

.border-amber-300\/40 {
  border-color: #fcd34d66;
}

.border-amber-300\/45 {
  border-color: #fcd34d73;
}

.border-amber-300\/5 {
  border-color: #fcd34d0d;
}

.border-amber-300\/50 {
  border-color: #fcd34d80;
}

.border-amber-300\/55 {
  border-color: #fcd34d8c;
}

.border-amber-300\/60 {
  border-color: #fcd34d99;
}

.border-amber-300\/65 {
  border-color: #fcd34da6;
}

.border-amber-300\/70 {
  border-color: #fcd34db3;
}

.border-amber-300\/75 {
  border-color: #fcd34dbf;
}

.border-amber-300\/80 {
  border-color: #fcd34dcc;
}

.border-amber-300\/85 {
  border-color: #fcd34dd9;
}

.border-amber-300\/90 {
  border-color: #fcd34de6;
}

.border-amber-300\/95 {
  border-color: #fcd34df2;
}

.border-cyan-100 {
  --tw-border-opacity: 1;
  border-color: rgb(207 250 254 / var(--tw-border-opacity));
}

.border-cyan-100\/0 {
  border-color: #cffafe00;
}

.border-cyan-100\/10 {
  border-color: #cffafe1a;
}

.border-cyan-100\/100 {
  border-color: #cffafe;
}

.border-cyan-100\/15 {
  border-color: #cffafe26;
}

.border-cyan-100\/20 {
  border-color: #cffafe33;
}

.border-cyan-100\/25 {
  border-color: #cffafe40;
}

.border-cyan-100\/30 {
  border-color: #cffafe4d;
}

.border-cyan-100\/35 {
  border-color: #cffafe59;
}

.border-cyan-100\/40 {
  border-color: #cffafe66;
}

.border-cyan-100\/45 {
  border-color: #cffafe73;
}

.border-cyan-100\/5 {
  border-color: #cffafe0d;
}

.border-cyan-100\/50 {
  border-color: #cffafe80;
}

.border-cyan-100\/55 {
  border-color: #cffafe8c;
}

.border-cyan-100\/60 {
  border-color: #cffafe99;
}

.border-cyan-100\/65 {
  border-color: #cffafea6;
}

.border-cyan-100\/70 {
  border-color: #cffafeb3;
}

.border-cyan-100\/75 {
  border-color: #cffafebf;
}

.border-cyan-100\/80 {
  border-color: #cffafecc;
}

.border-cyan-100\/85 {
  border-color: #cffafed9;
}

.border-cyan-100\/90 {
  border-color: #cffafee6;
}

.border-cyan-100\/95 {
  border-color: #cffafef2;
}

.border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

.border-cyan-300\/0 {
  border-color: #67e8f900;
}

.border-cyan-300\/10 {
  border-color: #67e8f91a;
}

.border-cyan-300\/100 {
  border-color: #67e8f9;
}

.border-cyan-300\/15 {
  border-color: #67e8f926;
}

.border-cyan-300\/20 {
  border-color: #67e8f933;
}

.border-cyan-300\/25 {
  border-color: #67e8f940;
}

.border-cyan-300\/30 {
  border-color: #67e8f94d;
}

.border-cyan-300\/35 {
  border-color: #67e8f959;
}

.border-cyan-300\/40 {
  border-color: #67e8f966;
}

.border-cyan-300\/45 {
  border-color: #67e8f973;
}

.border-cyan-300\/5 {
  border-color: #67e8f90d;
}

.border-cyan-300\/50 {
  border-color: #67e8f980;
}

.border-cyan-300\/55 {
  border-color: #67e8f98c;
}

.border-cyan-300\/60 {
  border-color: #67e8f999;
}

.border-cyan-300\/65 {
  border-color: #67e8f9a6;
}

.border-cyan-300\/70 {
  border-color: #67e8f9b3;
}

.border-cyan-300\/75 {
  border-color: #67e8f9bf;
}

.border-cyan-300\/80 {
  border-color: #67e8f9cc;
}

.border-cyan-300\/85 {
  border-color: #67e8f9d9;
}

.border-cyan-300\/90 {
  border-color: #67e8f9e6;
}

.border-cyan-300\/95 {
  border-color: #67e8f9f2;
}

.border-emerald-100 {
  --tw-border-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-emerald-100\/0 {
  border-color: #d1fae500;
}

.border-emerald-100\/10 {
  border-color: #d1fae51a;
}

.border-emerald-100\/100 {
  border-color: #d1fae5;
}

.border-emerald-100\/15 {
  border-color: #d1fae526;
}

.border-emerald-100\/20 {
  border-color: #d1fae533;
}

.border-emerald-100\/25 {
  border-color: #d1fae540;
}

.border-emerald-100\/30 {
  border-color: #d1fae54d;
}

.border-emerald-100\/35 {
  border-color: #d1fae559;
}

.border-emerald-100\/40 {
  border-color: #d1fae566;
}

.border-emerald-100\/45 {
  border-color: #d1fae573;
}

.border-emerald-100\/5 {
  border-color: #d1fae50d;
}

.border-emerald-100\/50 {
  border-color: #d1fae580;
}

.border-emerald-100\/55 {
  border-color: #d1fae58c;
}

.border-emerald-100\/60 {
  border-color: #d1fae599;
}

.border-emerald-100\/65 {
  border-color: #d1fae5a6;
}

.border-emerald-100\/70 {
  border-color: #d1fae5b3;
}

.border-emerald-100\/75 {
  border-color: #d1fae5bf;
}

.border-emerald-100\/80 {
  border-color: #d1fae5cc;
}

.border-emerald-100\/85 {
  border-color: #d1fae5d9;
}

.border-emerald-100\/90 {
  border-color: #d1fae5e6;
}

.border-emerald-100\/95 {
  border-color: #d1fae5f2;
}

.border-emerald-300 {
  --tw-border-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-emerald-300\/0 {
  border-color: #6ee7b700;
}

.border-emerald-300\/10 {
  border-color: #6ee7b71a;
}

.border-emerald-300\/100 {
  border-color: #6ee7b7;
}

.border-emerald-300\/15 {
  border-color: #6ee7b726;
}

.border-emerald-300\/20 {
  border-color: #6ee7b733;
}

.border-emerald-300\/25 {
  border-color: #6ee7b740;
}

.border-emerald-300\/30 {
  border-color: #6ee7b74d;
}

.border-emerald-300\/35 {
  border-color: #6ee7b759;
}

.border-emerald-300\/40 {
  border-color: #6ee7b766;
}

.border-emerald-300\/45 {
  border-color: #6ee7b773;
}

.border-emerald-300\/5 {
  border-color: #6ee7b70d;
}

.border-emerald-300\/50 {
  border-color: #6ee7b780;
}

.border-emerald-300\/55 {
  border-color: #6ee7b78c;
}

.border-emerald-300\/60 {
  border-color: #6ee7b799;
}

.border-emerald-300\/65 {
  border-color: #6ee7b7a6;
}

.border-emerald-300\/70 {
  border-color: #6ee7b7b3;
}

.border-emerald-300\/75 {
  border-color: #6ee7b7bf;
}

.border-emerald-300\/80 {
  border-color: #6ee7b7cc;
}

.border-emerald-300\/85 {
  border-color: #6ee7b7d9;
}

.border-emerald-300\/90 {
  border-color: #6ee7b7e6;
}

.border-emerald-300\/95 {
  border-color: #6ee7b7f2;
}

.border-error {
  --tw-border-opacity: 1;
  border-color: var(--fallback-er, oklch(var(--er) / var(--tw-border-opacity)));
}

.border-error-content {
  --tw-border-opacity: 1;
  border-color: var(--fallback-erc, oklch(var(--erc) / var(--tw-border-opacity)));
}

.border-error-content\/0 {
  border-color: var(--fallback-erc, oklch(var(--erc) / 0));
}

.border-error-content\/10 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .1));
}

.border-error-content\/100 {
  border-color: var(--fallback-erc, oklch(var(--erc) / 1));
}

.border-error-content\/15 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .15));
}

.border-error-content\/20 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .2));
}

.border-error-content\/25 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .25));
}

.border-error-content\/30 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .3));
}

.border-error-content\/35 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .35));
}

.border-error-content\/40 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .4));
}

.border-error-content\/45 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .45));
}

.border-error-content\/5 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .05));
}

.border-error-content\/50 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .5));
}

.border-error-content\/55 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .55));
}

.border-error-content\/60 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .6));
}

.border-error-content\/65 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .65));
}

.border-error-content\/70 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .7));
}

.border-error-content\/75 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .75));
}

.border-error-content\/80 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .8));
}

.border-error-content\/85 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .85));
}

.border-error-content\/90 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .9));
}

.border-error-content\/95 {
  border-color: var(--fallback-erc, oklch(var(--erc) / .95));
}

.border-error\/0 {
  border-color: var(--fallback-er, oklch(var(--er) / 0));
}

.border-error\/10 {
  border-color: var(--fallback-er, oklch(var(--er) / .1));
}

.border-error\/100 {
  border-color: var(--fallback-er, oklch(var(--er) / 1));
}

.border-error\/15 {
  border-color: var(--fallback-er, oklch(var(--er) / .15));
}

.border-error\/20 {
  border-color: var(--fallback-er, oklch(var(--er) / .2));
}

.border-error\/25 {
  border-color: var(--fallback-er, oklch(var(--er) / .25));
}

.border-error\/30 {
  border-color: var(--fallback-er, oklch(var(--er) / .3));
}

.border-error\/35 {
  border-color: var(--fallback-er, oklch(var(--er) / .35));
}

.border-error\/40 {
  border-color: var(--fallback-er, oklch(var(--er) / .4));
}

.border-error\/45 {
  border-color: var(--fallback-er, oklch(var(--er) / .45));
}

.border-error\/5 {
  border-color: var(--fallback-er, oklch(var(--er) / .05));
}

.border-error\/50 {
  border-color: var(--fallback-er, oklch(var(--er) / .5));
}

.border-error\/55 {
  border-color: var(--fallback-er, oklch(var(--er) / .55));
}

.border-error\/60 {
  border-color: var(--fallback-er, oklch(var(--er) / .6));
}

.border-error\/65 {
  border-color: var(--fallback-er, oklch(var(--er) / .65));
}

.border-error\/70 {
  border-color: var(--fallback-er, oklch(var(--er) / .7));
}

.border-error\/75 {
  border-color: var(--fallback-er, oklch(var(--er) / .75));
}

.border-error\/80 {
  border-color: var(--fallback-er, oklch(var(--er) / .8));
}

.border-error\/85 {
  border-color: var(--fallback-er, oklch(var(--er) / .85));
}

.border-error\/90 {
  border-color: var(--fallback-er, oklch(var(--er) / .9));
}

.border-error\/95 {
  border-color: var(--fallback-er, oklch(var(--er) / .95));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity));
}

.border-indigo-100\/0 {
  border-color: #e0e7ff00;
}

.border-indigo-100\/10 {
  border-color: #e0e7ff1a;
}

.border-indigo-100\/100 {
  border-color: #e0e7ff;
}

.border-indigo-100\/15 {
  border-color: #e0e7ff26;
}

.border-indigo-100\/20 {
  border-color: #e0e7ff33;
}

.border-indigo-100\/25 {
  border-color: #e0e7ff40;
}

.border-indigo-100\/30 {
  border-color: #e0e7ff4d;
}

.border-indigo-100\/35 {
  border-color: #e0e7ff59;
}

.border-indigo-100\/40 {
  border-color: #e0e7ff66;
}

.border-indigo-100\/45 {
  border-color: #e0e7ff73;
}

.border-indigo-100\/5 {
  border-color: #e0e7ff0d;
}

.border-indigo-100\/50 {
  border-color: #e0e7ff80;
}

.border-indigo-100\/55 {
  border-color: #e0e7ff8c;
}

.border-indigo-100\/60 {
  border-color: #e0e7ff99;
}

.border-indigo-100\/65 {
  border-color: #e0e7ffa6;
}

.border-indigo-100\/70 {
  border-color: #e0e7ffb3;
}

.border-indigo-100\/75 {
  border-color: #e0e7ffbf;
}

.border-indigo-100\/80 {
  border-color: #e0e7ffcc;
}

.border-indigo-100\/85 {
  border-color: #e0e7ffd9;
}

.border-indigo-100\/90 {
  border-color: #e0e7ffe6;
}

.border-indigo-100\/95 {
  border-color: #e0e7fff2;
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity));
}

.border-indigo-300\/0 {
  border-color: #a5b4fc00;
}

.border-indigo-300\/10 {
  border-color: #a5b4fc1a;
}

.border-indigo-300\/100 {
  border-color: #a5b4fc;
}

.border-indigo-300\/15 {
  border-color: #a5b4fc26;
}

.border-indigo-300\/20 {
  border-color: #a5b4fc33;
}

.border-indigo-300\/25 {
  border-color: #a5b4fc40;
}

.border-indigo-300\/30 {
  border-color: #a5b4fc4d;
}

.border-indigo-300\/35 {
  border-color: #a5b4fc59;
}

.border-indigo-300\/40 {
  border-color: #a5b4fc66;
}

.border-indigo-300\/45 {
  border-color: #a5b4fc73;
}

.border-indigo-300\/5 {
  border-color: #a5b4fc0d;
}

.border-indigo-300\/50 {
  border-color: #a5b4fc80;
}

.border-indigo-300\/55 {
  border-color: #a5b4fc8c;
}

.border-indigo-300\/60 {
  border-color: #a5b4fc99;
}

.border-indigo-300\/65 {
  border-color: #a5b4fca6;
}

.border-indigo-300\/70 {
  border-color: #a5b4fcb3;
}

.border-indigo-300\/75 {
  border-color: #a5b4fcbf;
}

.border-indigo-300\/80 {
  border-color: #a5b4fccc;
}

.border-indigo-300\/85 {
  border-color: #a5b4fcd9;
}

.border-indigo-300\/90 {
  border-color: #a5b4fce6;
}

.border-indigo-300\/95 {
  border-color: #a5b4fcf2;
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.border-info {
  --tw-border-opacity: 1;
  border-color: var(--fallback-in, oklch(var(--in) / var(--tw-border-opacity)));
}

.border-info-content {
  --tw-border-opacity: 1;
  border-color: var(--fallback-inc, oklch(var(--inc) / var(--tw-border-opacity)));
}

.border-info-content\/0 {
  border-color: var(--fallback-inc, oklch(var(--inc) / 0));
}

.border-info-content\/10 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .1));
}

.border-info-content\/100 {
  border-color: var(--fallback-inc, oklch(var(--inc) / 1));
}

.border-info-content\/15 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .15));
}

.border-info-content\/20 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .2));
}

.border-info-content\/25 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .25));
}

.border-info-content\/30 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .3));
}

.border-info-content\/35 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .35));
}

.border-info-content\/40 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .4));
}

.border-info-content\/45 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .45));
}

.border-info-content\/5 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .05));
}

.border-info-content\/50 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .5));
}

.border-info-content\/55 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .55));
}

.border-info-content\/60 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .6));
}

.border-info-content\/65 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .65));
}

.border-info-content\/70 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .7));
}

.border-info-content\/75 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .75));
}

.border-info-content\/80 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .8));
}

.border-info-content\/85 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .85));
}

.border-info-content\/90 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .9));
}

.border-info-content\/95 {
  border-color: var(--fallback-inc, oklch(var(--inc) / .95));
}

.border-info\/0 {
  border-color: var(--fallback-in, oklch(var(--in) / 0));
}

.border-info\/10 {
  border-color: var(--fallback-in, oklch(var(--in) / .1));
}

.border-info\/100 {
  border-color: var(--fallback-in, oklch(var(--in) / 1));
}

.border-info\/15 {
  border-color: var(--fallback-in, oklch(var(--in) / .15));
}

.border-info\/20 {
  border-color: var(--fallback-in, oklch(var(--in) / .2));
}

.border-info\/25 {
  border-color: var(--fallback-in, oklch(var(--in) / .25));
}

.border-info\/30 {
  border-color: var(--fallback-in, oklch(var(--in) / .3));
}

.border-info\/35 {
  border-color: var(--fallback-in, oklch(var(--in) / .35));
}

.border-info\/40 {
  border-color: var(--fallback-in, oklch(var(--in) / .4));
}

.border-info\/45 {
  border-color: var(--fallback-in, oklch(var(--in) / .45));
}

.border-info\/5 {
  border-color: var(--fallback-in, oklch(var(--in) / .05));
}

.border-info\/50 {
  border-color: var(--fallback-in, oklch(var(--in) / .5));
}

.border-info\/55 {
  border-color: var(--fallback-in, oklch(var(--in) / .55));
}

.border-info\/60 {
  border-color: var(--fallback-in, oklch(var(--in) / .6));
}

.border-info\/65 {
  border-color: var(--fallback-in, oklch(var(--in) / .65));
}

.border-info\/70 {
  border-color: var(--fallback-in, oklch(var(--in) / .7));
}

.border-info\/75 {
  border-color: var(--fallback-in, oklch(var(--in) / .75));
}

.border-info\/80 {
  border-color: var(--fallback-in, oklch(var(--in) / .8));
}

.border-info\/85 {
  border-color: var(--fallback-in, oklch(var(--in) / .85));
}

.border-info\/90 {
  border-color: var(--fallback-in, oklch(var(--in) / .9));
}

.border-info\/95 {
  border-color: var(--fallback-in, oklch(var(--in) / .95));
}

.border-neutral {
  --tw-border-opacity: 1;
  border-color: var(--fallback-n, oklch(var(--n) / var(--tw-border-opacity)));
}

.border-neutral-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-neutral-100\/0 {
  border-color: #f5f5f500;
}

.border-neutral-100\/10 {
  border-color: #f5f5f51a;
}

.border-neutral-100\/100 {
  border-color: #f5f5f5;
}

.border-neutral-100\/15 {
  border-color: #f5f5f526;
}

.border-neutral-100\/20 {
  border-color: #f5f5f533;
}

.border-neutral-100\/25 {
  border-color: #f5f5f540;
}

.border-neutral-100\/30 {
  border-color: #f5f5f54d;
}

.border-neutral-100\/35 {
  border-color: #f5f5f559;
}

.border-neutral-100\/40 {
  border-color: #f5f5f566;
}

.border-neutral-100\/45 {
  border-color: #f5f5f573;
}

.border-neutral-100\/5 {
  border-color: #f5f5f50d;
}

.border-neutral-100\/50 {
  border-color: #f5f5f580;
}

.border-neutral-100\/55 {
  border-color: #f5f5f58c;
}

.border-neutral-100\/60 {
  border-color: #f5f5f599;
}

.border-neutral-100\/65 {
  border-color: #f5f5f5a6;
}

.border-neutral-100\/70 {
  border-color: #f5f5f5b3;
}

.border-neutral-100\/75 {
  border-color: #f5f5f5bf;
}

.border-neutral-100\/80 {
  border-color: #f5f5f5cc;
}

.border-neutral-100\/85 {
  border-color: #f5f5f5d9;
}

.border-neutral-100\/90 {
  border-color: #f5f5f5e6;
}

.border-neutral-100\/95 {
  border-color: #f5f5f5f2;
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-neutral-200\/0 {
  border-color: #e5e5e500;
}

.border-neutral-200\/10 {
  border-color: #e5e5e51a;
}

.border-neutral-200\/100 {
  border-color: #e5e5e5;
}

.border-neutral-200\/15 {
  border-color: #e5e5e526;
}

.border-neutral-200\/20 {
  border-color: #e5e5e533;
}

.border-neutral-200\/25 {
  border-color: #e5e5e540;
}

.border-neutral-200\/30 {
  border-color: #e5e5e54d;
}

.border-neutral-200\/35 {
  border-color: #e5e5e559;
}

.border-neutral-200\/40 {
  border-color: #e5e5e566;
}

.border-neutral-200\/45 {
  border-color: #e5e5e573;
}

.border-neutral-200\/5 {
  border-color: #e5e5e50d;
}

.border-neutral-200\/50 {
  border-color: #e5e5e580;
}

.border-neutral-200\/55 {
  border-color: #e5e5e58c;
}

.border-neutral-200\/60 {
  border-color: #e5e5e599;
}

.border-neutral-200\/65 {
  border-color: #e5e5e5a6;
}

.border-neutral-200\/70 {
  border-color: #e5e5e5b3;
}

.border-neutral-200\/75 {
  border-color: #e5e5e5bf;
}

.border-neutral-200\/80 {
  border-color: #e5e5e5cc;
}

.border-neutral-200\/85 {
  border-color: #e5e5e5d9;
}

.border-neutral-200\/90 {
  border-color: #e5e5e5e6;
}

.border-neutral-200\/95 {
  border-color: #e5e5e5f2;
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-neutral-300\/0 {
  border-color: #d4d4d400;
}

.border-neutral-300\/10 {
  border-color: #d4d4d41a;
}

.border-neutral-300\/100 {
  border-color: #d4d4d4;
}

.border-neutral-300\/15 {
  border-color: #d4d4d426;
}

.border-neutral-300\/20 {
  border-color: #d4d4d433;
}

.border-neutral-300\/25 {
  border-color: #d4d4d440;
}

.border-neutral-300\/30 {
  border-color: #d4d4d44d;
}

.border-neutral-300\/35 {
  border-color: #d4d4d459;
}

.border-neutral-300\/40 {
  border-color: #d4d4d466;
}

.border-neutral-300\/45 {
  border-color: #d4d4d473;
}

.border-neutral-300\/5 {
  border-color: #d4d4d40d;
}

.border-neutral-300\/50 {
  border-color: #d4d4d480;
}

.border-neutral-300\/55 {
  border-color: #d4d4d48c;
}

.border-neutral-300\/60 {
  border-color: #d4d4d499;
}

.border-neutral-300\/65 {
  border-color: #d4d4d4a6;
}

.border-neutral-300\/70 {
  border-color: #d4d4d4b3;
}

.border-neutral-300\/75 {
  border-color: #d4d4d4bf;
}

.border-neutral-300\/80 {
  border-color: #d4d4d4cc;
}

.border-neutral-300\/85 {
  border-color: #d4d4d4d9;
}

.border-neutral-300\/90 {
  border-color: #d4d4d4e6;
}

.border-neutral-300\/95 {
  border-color: #d4d4d4f2;
}

.border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 163 163 / var(--tw-border-opacity));
}

.border-neutral-400\/0 {
  border-color: #a3a3a300;
}

.border-neutral-400\/10 {
  border-color: #a3a3a31a;
}

.border-neutral-400\/100 {
  border-color: #a3a3a3;
}

.border-neutral-400\/15 {
  border-color: #a3a3a326;
}

.border-neutral-400\/20 {
  border-color: #a3a3a333;
}

.border-neutral-400\/25 {
  border-color: #a3a3a340;
}

.border-neutral-400\/30 {
  border-color: #a3a3a34d;
}

.border-neutral-400\/35 {
  border-color: #a3a3a359;
}

.border-neutral-400\/40 {
  border-color: #a3a3a366;
}

.border-neutral-400\/45 {
  border-color: #a3a3a373;
}

.border-neutral-400\/5 {
  border-color: #a3a3a30d;
}

.border-neutral-400\/50 {
  border-color: #a3a3a380;
}

.border-neutral-400\/55 {
  border-color: #a3a3a38c;
}

.border-neutral-400\/60 {
  border-color: #a3a3a399;
}

.border-neutral-400\/65 {
  border-color: #a3a3a3a6;
}

.border-neutral-400\/70 {
  border-color: #a3a3a3b3;
}

.border-neutral-400\/75 {
  border-color: #a3a3a3bf;
}

.border-neutral-400\/80 {
  border-color: #a3a3a3cc;
}

.border-neutral-400\/85 {
  border-color: #a3a3a3d9;
}

.border-neutral-400\/90 {
  border-color: #a3a3a3e6;
}

.border-neutral-400\/95 {
  border-color: #a3a3a3f2;
}

.border-neutral-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.border-neutral-50\/0 {
  border-color: #fafafa00;
}

.border-neutral-50\/10 {
  border-color: #fafafa1a;
}

.border-neutral-50\/100 {
  border-color: #fafafa;
}

.border-neutral-50\/15 {
  border-color: #fafafa26;
}

.border-neutral-50\/20 {
  border-color: #fafafa33;
}

.border-neutral-50\/25 {
  border-color: #fafafa40;
}

.border-neutral-50\/30 {
  border-color: #fafafa4d;
}

.border-neutral-50\/35 {
  border-color: #fafafa59;
}

.border-neutral-50\/40 {
  border-color: #fafafa66;
}

.border-neutral-50\/45 {
  border-color: #fafafa73;
}

.border-neutral-50\/5 {
  border-color: #fafafa0d;
}

.border-neutral-50\/50 {
  border-color: #fafafa80;
}

.border-neutral-50\/55 {
  border-color: #fafafa8c;
}

.border-neutral-50\/60 {
  border-color: #fafafa99;
}

.border-neutral-50\/65 {
  border-color: #fafafaa6;
}

.border-neutral-50\/70 {
  border-color: #fafafab3;
}

.border-neutral-50\/75 {
  border-color: #fafafabf;
}

.border-neutral-50\/80 {
  border-color: #fafafacc;
}

.border-neutral-50\/85 {
  border-color: #fafafad9;
}

.border-neutral-50\/90 {
  border-color: #fafafae6;
}

.border-neutral-50\/95 {
  border-color: #fafafaf2;
}

.border-neutral-500 {
  --tw-border-opacity: 1;
  border-color: rgb(115 115 115 / var(--tw-border-opacity));
}

.border-neutral-500\/0 {
  border-color: #73737300;
}

.border-neutral-500\/10 {
  border-color: #7373731a;
}

.border-neutral-500\/100 {
  border-color: #737373;
}

.border-neutral-500\/15 {
  border-color: #73737326;
}

.border-neutral-500\/20 {
  border-color: #73737333;
}

.border-neutral-500\/25 {
  border-color: #73737340;
}

.border-neutral-500\/30 {
  border-color: #7373734d;
}

.border-neutral-500\/35 {
  border-color: #73737359;
}

.border-neutral-500\/40 {
  border-color: #73737366;
}

.border-neutral-500\/45 {
  border-color: #73737373;
}

.border-neutral-500\/5 {
  border-color: #7373730d;
}

.border-neutral-500\/50 {
  border-color: #73737380;
}

.border-neutral-500\/55 {
  border-color: #7373738c;
}

.border-neutral-500\/60 {
  border-color: #73737399;
}

.border-neutral-500\/65 {
  border-color: #737373a6;
}

.border-neutral-500\/70 {
  border-color: #737373b3;
}

.border-neutral-500\/75 {
  border-color: #737373bf;
}

.border-neutral-500\/80 {
  border-color: #737373cc;
}

.border-neutral-500\/85 {
  border-color: #737373d9;
}

.border-neutral-500\/90 {
  border-color: #737373e6;
}

.border-neutral-500\/95 {
  border-color: #737373f2;
}

.border-neutral-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity));
}

.border-neutral-600\/0 {
  border-color: #52525200;
}

.border-neutral-600\/10 {
  border-color: #5252521a;
}

.border-neutral-600\/100 {
  border-color: #525252;
}

.border-neutral-600\/15 {
  border-color: #52525226;
}

.border-neutral-600\/20 {
  border-color: #52525233;
}

.border-neutral-600\/25 {
  border-color: #52525240;
}

.border-neutral-600\/30 {
  border-color: #5252524d;
}

.border-neutral-600\/35 {
  border-color: #52525259;
}

.border-neutral-600\/40 {
  border-color: #52525266;
}

.border-neutral-600\/45 {
  border-color: #52525273;
}

.border-neutral-600\/5 {
  border-color: #5252520d;
}

.border-neutral-600\/50 {
  border-color: #52525280;
}

.border-neutral-600\/55 {
  border-color: #5252528c;
}

.border-neutral-600\/60 {
  border-color: #52525299;
}

.border-neutral-600\/65 {
  border-color: #525252a6;
}

.border-neutral-600\/70 {
  border-color: #525252b3;
}

.border-neutral-600\/75 {
  border-color: #525252bf;
}

.border-neutral-600\/80 {
  border-color: #525252cc;
}

.border-neutral-600\/85 {
  border-color: #525252d9;
}

.border-neutral-600\/90 {
  border-color: #525252e6;
}

.border-neutral-600\/95 {
  border-color: #525252f2;
}

.border-neutral-700 {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.border-neutral-700\/0 {
  border-color: #40404000;
}

.border-neutral-700\/10 {
  border-color: #4040401a;
}

.border-neutral-700\/100 {
  border-color: #404040;
}

.border-neutral-700\/15 {
  border-color: #40404026;
}

.border-neutral-700\/20 {
  border-color: #40404033;
}

.border-neutral-700\/25 {
  border-color: #40404040;
}

.border-neutral-700\/30 {
  border-color: #4040404d;
}

.border-neutral-700\/35 {
  border-color: #40404059;
}

.border-neutral-700\/40 {
  border-color: #40404066;
}

.border-neutral-700\/45 {
  border-color: #40404073;
}

.border-neutral-700\/5 {
  border-color: #4040400d;
}

.border-neutral-700\/50 {
  border-color: #40404080;
}

.border-neutral-700\/55 {
  border-color: #4040408c;
}

.border-neutral-700\/60 {
  border-color: #40404099;
}

.border-neutral-700\/65 {
  border-color: #404040a6;
}

.border-neutral-700\/70 {
  border-color: #404040b3;
}

.border-neutral-700\/75 {
  border-color: #404040bf;
}

.border-neutral-700\/80 {
  border-color: #404040cc;
}

.border-neutral-700\/85 {
  border-color: #404040d9;
}

.border-neutral-700\/90 {
  border-color: #404040e6;
}

.border-neutral-700\/95 {
  border-color: #404040f2;
}

.border-neutral-800 {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

.border-neutral-800\/0 {
  border-color: #26262600;
}

.border-neutral-800\/10 {
  border-color: #2626261a;
}

.border-neutral-800\/100 {
  border-color: #262626;
}

.border-neutral-800\/15 {
  border-color: #26262626;
}

.border-neutral-800\/20 {
  border-color: #26262633;
}

.border-neutral-800\/25 {
  border-color: #26262640;
}

.border-neutral-800\/30 {
  border-color: #2626264d;
}

.border-neutral-800\/35 {
  border-color: #26262659;
}

.border-neutral-800\/40 {
  border-color: #26262666;
}

.border-neutral-800\/45 {
  border-color: #26262673;
}

.border-neutral-800\/5 {
  border-color: #2626260d;
}

.border-neutral-800\/50 {
  border-color: #26262680;
}

.border-neutral-800\/55 {
  border-color: #2626268c;
}

.border-neutral-800\/60 {
  border-color: #26262699;
}

.border-neutral-800\/65 {
  border-color: #262626a6;
}

.border-neutral-800\/70 {
  border-color: #262626b3;
}

.border-neutral-800\/75 {
  border-color: #262626bf;
}

.border-neutral-800\/80 {
  border-color: #262626cc;
}

.border-neutral-800\/85 {
  border-color: #262626d9;
}

.border-neutral-800\/90 {
  border-color: #262626e6;
}

.border-neutral-800\/95 {
  border-color: #262626f2;
}

.border-neutral-900 {
  --tw-border-opacity: 1;
  border-color: rgb(23 23 23 / var(--tw-border-opacity));
}

.border-neutral-900\/0 {
  border-color: #17171700;
}

.border-neutral-900\/10 {
  border-color: #1717171a;
}

.border-neutral-900\/100 {
  border-color: #171717;
}

.border-neutral-900\/15 {
  border-color: #17171726;
}

.border-neutral-900\/20 {
  border-color: #17171733;
}

.border-neutral-900\/25 {
  border-color: #17171740;
}

.border-neutral-900\/30 {
  border-color: #1717174d;
}

.border-neutral-900\/35 {
  border-color: #17171759;
}

.border-neutral-900\/40 {
  border-color: #17171766;
}

.border-neutral-900\/45 {
  border-color: #17171773;
}

.border-neutral-900\/5 {
  border-color: #1717170d;
}

.border-neutral-900\/50 {
  border-color: #17171780;
}

.border-neutral-900\/55 {
  border-color: #1717178c;
}

.border-neutral-900\/60 {
  border-color: #17171799;
}

.border-neutral-900\/65 {
  border-color: #171717a6;
}

.border-neutral-900\/70 {
  border-color: #171717b3;
}

.border-neutral-900\/75 {
  border-color: #171717bf;
}

.border-neutral-900\/80 {
  border-color: #171717cc;
}

.border-neutral-900\/85 {
  border-color: #171717d9;
}

.border-neutral-900\/90 {
  border-color: #171717e6;
}

.border-neutral-900\/95 {
  border-color: #171717f2;
}

.border-neutral-950 {
  --tw-border-opacity: 1;
  border-color: rgb(10 10 10 / var(--tw-border-opacity));
}

.border-neutral-950\/0 {
  border-color: #0a0a0a00;
}

.border-neutral-950\/10 {
  border-color: #0a0a0a1a;
}

.border-neutral-950\/100 {
  border-color: #0a0a0a;
}

.border-neutral-950\/15 {
  border-color: #0a0a0a26;
}

.border-neutral-950\/20 {
  border-color: #0a0a0a33;
}

.border-neutral-950\/25 {
  border-color: #0a0a0a40;
}

.border-neutral-950\/30 {
  border-color: #0a0a0a4d;
}

.border-neutral-950\/35 {
  border-color: #0a0a0a59;
}

.border-neutral-950\/40 {
  border-color: #0a0a0a66;
}

.border-neutral-950\/45 {
  border-color: #0a0a0a73;
}

.border-neutral-950\/5 {
  border-color: #0a0a0a0d;
}

.border-neutral-950\/50 {
  border-color: #0a0a0a80;
}

.border-neutral-950\/55 {
  border-color: #0a0a0a8c;
}

.border-neutral-950\/60 {
  border-color: #0a0a0a99;
}

.border-neutral-950\/65 {
  border-color: #0a0a0aa6;
}

.border-neutral-950\/70 {
  border-color: #0a0a0ab3;
}

.border-neutral-950\/75 {
  border-color: #0a0a0abf;
}

.border-neutral-950\/80 {
  border-color: #0a0a0acc;
}

.border-neutral-950\/85 {
  border-color: #0a0a0ad9;
}

.border-neutral-950\/90 {
  border-color: #0a0a0ae6;
}

.border-neutral-950\/95 {
  border-color: #0a0a0af2;
}

.border-neutral-content {
  --tw-border-opacity: 1;
  border-color: var(--fallback-nc, oklch(var(--nc) / var(--tw-border-opacity)));
}

.border-neutral-content\/0 {
  border-color: var(--fallback-nc, oklch(var(--nc) / 0));
}

.border-neutral-content\/10 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .1));
}

.border-neutral-content\/100 {
  border-color: var(--fallback-nc, oklch(var(--nc) / 1));
}

.border-neutral-content\/15 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .15));
}

.border-neutral-content\/20 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .2));
}

.border-neutral-content\/25 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .25));
}

.border-neutral-content\/30 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .3));
}

.border-neutral-content\/35 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .35));
}

.border-neutral-content\/40 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .4));
}

.border-neutral-content\/45 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .45));
}

.border-neutral-content\/5 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .05));
}

.border-neutral-content\/50 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .5));
}

.border-neutral-content\/55 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .55));
}

.border-neutral-content\/60 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .6));
}

.border-neutral-content\/65 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .65));
}

.border-neutral-content\/70 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .7));
}

.border-neutral-content\/75 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .75));
}

.border-neutral-content\/80 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .8));
}

.border-neutral-content\/85 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .85));
}

.border-neutral-content\/90 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .9));
}

.border-neutral-content\/95 {
  border-color: var(--fallback-nc, oklch(var(--nc) / .95));
}

.border-neutral\/0 {
  border-color: var(--fallback-n, oklch(var(--n) / 0));
}

.border-neutral\/10 {
  border-color: var(--fallback-n, oklch(var(--n) / .1));
}

.border-neutral\/100 {
  border-color: var(--fallback-n, oklch(var(--n) / 1));
}

.border-neutral\/15 {
  border-color: var(--fallback-n, oklch(var(--n) / .15));
}

.border-neutral\/20 {
  border-color: var(--fallback-n, oklch(var(--n) / .2));
}

.border-neutral\/25 {
  border-color: var(--fallback-n, oklch(var(--n) / .25));
}

.border-neutral\/30 {
  border-color: var(--fallback-n, oklch(var(--n) / .3));
}

.border-neutral\/35 {
  border-color: var(--fallback-n, oklch(var(--n) / .35));
}

.border-neutral\/40 {
  border-color: var(--fallback-n, oklch(var(--n) / .4));
}

.border-neutral\/45 {
  border-color: var(--fallback-n, oklch(var(--n) / .45));
}

.border-neutral\/5 {
  border-color: var(--fallback-n, oklch(var(--n) / .05));
}

.border-neutral\/50 {
  border-color: var(--fallback-n, oklch(var(--n) / .5));
}

.border-neutral\/55 {
  border-color: var(--fallback-n, oklch(var(--n) / .55));
}

.border-neutral\/60 {
  border-color: var(--fallback-n, oklch(var(--n) / .6));
}

.border-neutral\/65 {
  border-color: var(--fallback-n, oklch(var(--n) / .65));
}

.border-neutral\/70 {
  border-color: var(--fallback-n, oklch(var(--n) / .7));
}

.border-neutral\/75 {
  border-color: var(--fallback-n, oklch(var(--n) / .75));
}

.border-neutral\/80 {
  border-color: var(--fallback-n, oklch(var(--n) / .8));
}

.border-neutral\/85 {
  border-color: var(--fallback-n, oklch(var(--n) / .85));
}

.border-neutral\/90 {
  border-color: var(--fallback-n, oklch(var(--n) / .9));
}

.border-neutral\/95 {
  border-color: var(--fallback-n, oklch(var(--n) / .95));
}

.border-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 237 213 / var(--tw-border-opacity));
}

.border-orange-100\/0 {
  border-color: #ffedd500;
}

.border-orange-100\/10 {
  border-color: #ffedd51a;
}

.border-orange-100\/100 {
  border-color: #ffedd5;
}

.border-orange-100\/15 {
  border-color: #ffedd526;
}

.border-orange-100\/20 {
  border-color: #ffedd533;
}

.border-orange-100\/25 {
  border-color: #ffedd540;
}

.border-orange-100\/30 {
  border-color: #ffedd54d;
}

.border-orange-100\/35 {
  border-color: #ffedd559;
}

.border-orange-100\/40 {
  border-color: #ffedd566;
}

.border-orange-100\/45 {
  border-color: #ffedd573;
}

.border-orange-100\/5 {
  border-color: #ffedd50d;
}

.border-orange-100\/50 {
  border-color: #ffedd580;
}

.border-orange-100\/55 {
  border-color: #ffedd58c;
}

.border-orange-100\/60 {
  border-color: #ffedd599;
}

.border-orange-100\/65 {
  border-color: #ffedd5a6;
}

.border-orange-100\/70 {
  border-color: #ffedd5b3;
}

.border-orange-100\/75 {
  border-color: #ffedd5bf;
}

.border-orange-100\/80 {
  border-color: #ffedd5cc;
}

.border-orange-100\/85 {
  border-color: #ffedd5d9;
}

.border-orange-100\/90 {
  border-color: #ffedd5e6;
}

.border-orange-100\/95 {
  border-color: #ffedd5f2;
}

.border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 116 / var(--tw-border-opacity));
}

.border-orange-300\/0 {
  border-color: #fdba7400;
}

.border-orange-300\/10 {
  border-color: #fdba741a;
}

.border-orange-300\/100 {
  border-color: #fdba74;
}

.border-orange-300\/15 {
  border-color: #fdba7426;
}

.border-orange-300\/20 {
  border-color: #fdba7433;
}

.border-orange-300\/25 {
  border-color: #fdba7440;
}

.border-orange-300\/30 {
  border-color: #fdba744d;
}

.border-orange-300\/35 {
  border-color: #fdba7459;
}

.border-orange-300\/40 {
  border-color: #fdba7466;
}

.border-orange-300\/45 {
  border-color: #fdba7473;
}

.border-orange-300\/5 {
  border-color: #fdba740d;
}

.border-orange-300\/50 {
  border-color: #fdba7480;
}

.border-orange-300\/55 {
  border-color: #fdba748c;
}

.border-orange-300\/60 {
  border-color: #fdba7499;
}

.border-orange-300\/65 {
  border-color: #fdba74a6;
}

.border-orange-300\/70 {
  border-color: #fdba74b3;
}

.border-orange-300\/75 {
  border-color: #fdba74bf;
}

.border-orange-300\/80 {
  border-color: #fdba74cc;
}

.border-orange-300\/85 {
  border-color: #fdba74d9;
}

.border-orange-300\/90 {
  border-color: #fdba74e6;
}

.border-orange-300\/95 {
  border-color: #fdba74f2;
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
}

.border-primary-content {
  --tw-border-opacity: 1;
  border-color: var(--fallback-pc, oklch(var(--pc) / var(--tw-border-opacity)));
}

.border-primary-content\/0 {
  border-color: var(--fallback-pc, oklch(var(--pc) / 0));
}

.border-primary-content\/10 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .1));
}

.border-primary-content\/100 {
  border-color: var(--fallback-pc, oklch(var(--pc) / 1));
}

.border-primary-content\/15 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .15));
}

.border-primary-content\/20 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .2));
}

.border-primary-content\/25 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .25));
}

.border-primary-content\/30 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .3));
}

.border-primary-content\/35 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .35));
}

.border-primary-content\/40 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .4));
}

.border-primary-content\/45 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .45));
}

.border-primary-content\/5 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .05));
}

.border-primary-content\/50 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .5));
}

.border-primary-content\/55 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .55));
}

.border-primary-content\/60 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .6));
}

.border-primary-content\/65 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .65));
}

.border-primary-content\/70 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .7));
}

.border-primary-content\/75 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .75));
}

.border-primary-content\/80 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .8));
}

.border-primary-content\/85 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .85));
}

.border-primary-content\/90 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .9));
}

.border-primary-content\/95 {
  border-color: var(--fallback-pc, oklch(var(--pc) / .95));
}

.border-primary\/0 {
  border-color: var(--fallback-p, oklch(var(--p) / 0));
}

.border-primary\/10 {
  border-color: var(--fallback-p, oklch(var(--p) / .1));
}

.border-primary\/100 {
  border-color: var(--fallback-p, oklch(var(--p) / 1));
}

.border-primary\/15 {
  border-color: var(--fallback-p, oklch(var(--p) / .15));
}

.border-primary\/20 {
  border-color: var(--fallback-p, oklch(var(--p) / .2));
}

.border-primary\/25 {
  border-color: var(--fallback-p, oklch(var(--p) / .25));
}

.border-primary\/30 {
  border-color: var(--fallback-p, oklch(var(--p) / .3));
}

.border-primary\/35 {
  border-color: var(--fallback-p, oklch(var(--p) / .35));
}

.border-primary\/40 {
  border-color: var(--fallback-p, oklch(var(--p) / .4));
}

.border-primary\/45 {
  border-color: var(--fallback-p, oklch(var(--p) / .45));
}

.border-primary\/5 {
  border-color: var(--fallback-p, oklch(var(--p) / .05));
}

.border-primary\/50 {
  border-color: var(--fallback-p, oklch(var(--p) / .5));
}

.border-primary\/55 {
  border-color: var(--fallback-p, oklch(var(--p) / .55));
}

.border-primary\/60 {
  border-color: var(--fallback-p, oklch(var(--p) / .6));
}

.border-primary\/65 {
  border-color: var(--fallback-p, oklch(var(--p) / .65));
}

.border-primary\/70 {
  border-color: var(--fallback-p, oklch(var(--p) / .7));
}

.border-primary\/75 {
  border-color: var(--fallback-p, oklch(var(--p) / .75));
}

.border-primary\/80 {
  border-color: var(--fallback-p, oklch(var(--p) / .8));
}

.border-primary\/85 {
  border-color: var(--fallback-p, oklch(var(--p) / .85));
}

.border-primary\/90 {
  border-color: var(--fallback-p, oklch(var(--p) / .9));
}

.border-primary\/95 {
  border-color: var(--fallback-p, oklch(var(--p) / .95));
}

.border-rose-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 228 230 / var(--tw-border-opacity));
}

.border-rose-100\/0 {
  border-color: #ffe4e600;
}

.border-rose-100\/10 {
  border-color: #ffe4e61a;
}

.border-rose-100\/100 {
  border-color: #ffe4e6;
}

.border-rose-100\/15 {
  border-color: #ffe4e626;
}

.border-rose-100\/20 {
  border-color: #ffe4e633;
}

.border-rose-100\/25 {
  border-color: #ffe4e640;
}

.border-rose-100\/30 {
  border-color: #ffe4e64d;
}

.border-rose-100\/35 {
  border-color: #ffe4e659;
}

.border-rose-100\/40 {
  border-color: #ffe4e666;
}

.border-rose-100\/45 {
  border-color: #ffe4e673;
}

.border-rose-100\/5 {
  border-color: #ffe4e60d;
}

.border-rose-100\/50 {
  border-color: #ffe4e680;
}

.border-rose-100\/55 {
  border-color: #ffe4e68c;
}

.border-rose-100\/60 {
  border-color: #ffe4e699;
}

.border-rose-100\/65 {
  border-color: #ffe4e6a6;
}

.border-rose-100\/70 {
  border-color: #ffe4e6b3;
}

.border-rose-100\/75 {
  border-color: #ffe4e6bf;
}

.border-rose-100\/80 {
  border-color: #ffe4e6cc;
}

.border-rose-100\/85 {
  border-color: #ffe4e6d9;
}

.border-rose-100\/90 {
  border-color: #ffe4e6e6;
}

.border-rose-100\/95 {
  border-color: #ffe4e6f2;
}

.border-rose-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 164 175 / var(--tw-border-opacity));
}

.border-rose-300\/0 {
  border-color: #fda4af00;
}

.border-rose-300\/10 {
  border-color: #fda4af1a;
}

.border-rose-300\/100 {
  border-color: #fda4af;
}

.border-rose-300\/15 {
  border-color: #fda4af26;
}

.border-rose-300\/20 {
  border-color: #fda4af33;
}

.border-rose-300\/25 {
  border-color: #fda4af40;
}

.border-rose-300\/30 {
  border-color: #fda4af4d;
}

.border-rose-300\/35 {
  border-color: #fda4af59;
}

.border-rose-300\/40 {
  border-color: #fda4af66;
}

.border-rose-300\/45 {
  border-color: #fda4af73;
}

.border-rose-300\/5 {
  border-color: #fda4af0d;
}

.border-rose-300\/50 {
  border-color: #fda4af80;
}

.border-rose-300\/55 {
  border-color: #fda4af8c;
}

.border-rose-300\/60 {
  border-color: #fda4af99;
}

.border-rose-300\/65 {
  border-color: #fda4afa6;
}

.border-rose-300\/70 {
  border-color: #fda4afb3;
}

.border-rose-300\/75 {
  border-color: #fda4afbf;
}

.border-rose-300\/80 {
  border-color: #fda4afcc;
}

.border-rose-300\/85 {
  border-color: #fda4afd9;
}

.border-rose-300\/90 {
  border-color: #fda4afe6;
}

.border-rose-300\/95 {
  border-color: #fda4aff2;
}

.border-success {
  --tw-border-opacity: 1;
  border-color: var(--fallback-su, oklch(var(--su) / var(--tw-border-opacity)));
}

.border-success-content {
  --tw-border-opacity: 1;
  border-color: var(--fallback-suc, oklch(var(--suc) / var(--tw-border-opacity)));
}

.border-success-content\/0 {
  border-color: var(--fallback-suc, oklch(var(--suc) / 0));
}

.border-success-content\/10 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .1));
}

.border-success-content\/100 {
  border-color: var(--fallback-suc, oklch(var(--suc) / 1));
}

.border-success-content\/15 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .15));
}

.border-success-content\/20 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .2));
}

.border-success-content\/25 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .25));
}

.border-success-content\/30 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .3));
}

.border-success-content\/35 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .35));
}

.border-success-content\/40 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .4));
}

.border-success-content\/45 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .45));
}

.border-success-content\/5 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .05));
}

.border-success-content\/50 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .5));
}

.border-success-content\/55 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .55));
}

.border-success-content\/60 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .6));
}

.border-success-content\/65 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .65));
}

.border-success-content\/70 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .7));
}

.border-success-content\/75 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .75));
}

.border-success-content\/80 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .8));
}

.border-success-content\/85 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .85));
}

.border-success-content\/90 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .9));
}

.border-success-content\/95 {
  border-color: var(--fallback-suc, oklch(var(--suc) / .95));
}

.border-success\/0 {
  border-color: var(--fallback-su, oklch(var(--su) / 0));
}

.border-success\/10 {
  border-color: var(--fallback-su, oklch(var(--su) / .1));
}

.border-success\/100 {
  border-color: var(--fallback-su, oklch(var(--su) / 1));
}

.border-success\/15 {
  border-color: var(--fallback-su, oklch(var(--su) / .15));
}

.border-success\/20 {
  border-color: var(--fallback-su, oklch(var(--su) / .2));
}

.border-success\/25 {
  border-color: var(--fallback-su, oklch(var(--su) / .25));
}

.border-success\/30 {
  border-color: var(--fallback-su, oklch(var(--su) / .3));
}

.border-success\/35 {
  border-color: var(--fallback-su, oklch(var(--su) / .35));
}

.border-success\/40 {
  border-color: var(--fallback-su, oklch(var(--su) / .4));
}

.border-success\/45 {
  border-color: var(--fallback-su, oklch(var(--su) / .45));
}

.border-success\/5 {
  border-color: var(--fallback-su, oklch(var(--su) / .05));
}

.border-success\/50 {
  border-color: var(--fallback-su, oklch(var(--su) / .5));
}

.border-success\/55 {
  border-color: var(--fallback-su, oklch(var(--su) / .55));
}

.border-success\/60 {
  border-color: var(--fallback-su, oklch(var(--su) / .6));
}

.border-success\/65 {
  border-color: var(--fallback-su, oklch(var(--su) / .65));
}

.border-success\/70 {
  border-color: var(--fallback-su, oklch(var(--su) / .7));
}

.border-success\/75 {
  border-color: var(--fallback-su, oklch(var(--su) / .75));
}

.border-success\/80 {
  border-color: var(--fallback-su, oklch(var(--su) / .8));
}

.border-success\/85 {
  border-color: var(--fallback-su, oklch(var(--su) / .85));
}

.border-success\/90 {
  border-color: var(--fallback-su, oklch(var(--su) / .9));
}

.border-success\/95 {
  border-color: var(--fallback-su, oklch(var(--su) / .95));
}

.border-transparent {
  border-color: #0000;
}

.border-violet-100 {
  --tw-border-opacity: 1;
  border-color: rgb(237 233 254 / var(--tw-border-opacity));
}

.border-violet-100\/0 {
  border-color: #ede9fe00;
}

.border-violet-100\/10 {
  border-color: #ede9fe1a;
}

.border-violet-100\/100 {
  border-color: #ede9fe;
}

.border-violet-100\/15 {
  border-color: #ede9fe26;
}

.border-violet-100\/20 {
  border-color: #ede9fe33;
}

.border-violet-100\/25 {
  border-color: #ede9fe40;
}

.border-violet-100\/30 {
  border-color: #ede9fe4d;
}

.border-violet-100\/35 {
  border-color: #ede9fe59;
}

.border-violet-100\/40 {
  border-color: #ede9fe66;
}

.border-violet-100\/45 {
  border-color: #ede9fe73;
}

.border-violet-100\/5 {
  border-color: #ede9fe0d;
}

.border-violet-100\/50 {
  border-color: #ede9fe80;
}

.border-violet-100\/55 {
  border-color: #ede9fe8c;
}

.border-violet-100\/60 {
  border-color: #ede9fe99;
}

.border-violet-100\/65 {
  border-color: #ede9fea6;
}

.border-violet-100\/70 {
  border-color: #ede9feb3;
}

.border-violet-100\/75 {
  border-color: #ede9febf;
}

.border-violet-100\/80 {
  border-color: #ede9fecc;
}

.border-violet-100\/85 {
  border-color: #ede9fed9;
}

.border-violet-100\/90 {
  border-color: #ede9fee6;
}

.border-violet-100\/95 {
  border-color: #ede9fef2;
}

.border-violet-300 {
  --tw-border-opacity: 1;
  border-color: rgb(196 181 253 / var(--tw-border-opacity));
}

.border-violet-300\/0 {
  border-color: #c4b5fd00;
}

.border-violet-300\/10 {
  border-color: #c4b5fd1a;
}

.border-violet-300\/100 {
  border-color: #c4b5fd;
}

.border-violet-300\/15 {
  border-color: #c4b5fd26;
}

.border-violet-300\/20 {
  border-color: #c4b5fd33;
}

.border-violet-300\/25 {
  border-color: #c4b5fd40;
}

.border-violet-300\/30 {
  border-color: #c4b5fd4d;
}

.border-violet-300\/35 {
  border-color: #c4b5fd59;
}

.border-violet-300\/40 {
  border-color: #c4b5fd66;
}

.border-violet-300\/45 {
  border-color: #c4b5fd73;
}

.border-violet-300\/5 {
  border-color: #c4b5fd0d;
}

.border-violet-300\/50 {
  border-color: #c4b5fd80;
}

.border-violet-300\/55 {
  border-color: #c4b5fd8c;
}

.border-violet-300\/60 {
  border-color: #c4b5fd99;
}

.border-violet-300\/65 {
  border-color: #c4b5fda6;
}

.border-violet-300\/70 {
  border-color: #c4b5fdb3;
}

.border-violet-300\/75 {
  border-color: #c4b5fdbf;
}

.border-violet-300\/80 {
  border-color: #c4b5fdcc;
}

.border-violet-300\/85 {
  border-color: #c4b5fdd9;
}

.border-violet-300\/90 {
  border-color: #c4b5fde6;
}

.border-violet-300\/95 {
  border-color: #c4b5fdf2;
}

.border-warning {
  --tw-border-opacity: 1;
  border-color: var(--fallback-wa, oklch(var(--wa) / var(--tw-border-opacity)));
}

.border-warning-content {
  --tw-border-opacity: 1;
  border-color: var(--fallback-wac, oklch(var(--wac) / var(--tw-border-opacity)));
}

.border-warning-content\/0 {
  border-color: var(--fallback-wac, oklch(var(--wac) / 0));
}

.border-warning-content\/10 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .1));
}

.border-warning-content\/100 {
  border-color: var(--fallback-wac, oklch(var(--wac) / 1));
}

.border-warning-content\/15 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .15));
}

.border-warning-content\/20 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .2));
}

.border-warning-content\/25 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .25));
}

.border-warning-content\/30 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .3));
}

.border-warning-content\/35 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .35));
}

.border-warning-content\/40 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .4));
}

.border-warning-content\/45 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .45));
}

.border-warning-content\/5 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .05));
}

.border-warning-content\/50 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .5));
}

.border-warning-content\/55 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .55));
}

.border-warning-content\/60 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .6));
}

.border-warning-content\/65 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .65));
}

.border-warning-content\/70 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .7));
}

.border-warning-content\/75 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .75));
}

.border-warning-content\/80 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .8));
}

.border-warning-content\/85 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .85));
}

.border-warning-content\/90 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .9));
}

.border-warning-content\/95 {
  border-color: var(--fallback-wac, oklch(var(--wac) / .95));
}

.border-warning-orange {
  --tw-border-opacity: 1;
  border-color: rgb(255 142 41 / var(--tw-border-opacity));
}

.border-warning-orange\/0 {
  border-color: #ff8e2900;
}

.border-warning-orange\/10 {
  border-color: #ff8e291a;
}

.border-warning-orange\/100 {
  border-color: #ff8e29;
}

.border-warning-orange\/15 {
  border-color: #ff8e2926;
}

.border-warning-orange\/20 {
  border-color: #ff8e2933;
}

.border-warning-orange\/25 {
  border-color: #ff8e2940;
}

.border-warning-orange\/30 {
  border-color: #ff8e294d;
}

.border-warning-orange\/35 {
  border-color: #ff8e2959;
}

.border-warning-orange\/40 {
  border-color: #ff8e2966;
}

.border-warning-orange\/45 {
  border-color: #ff8e2973;
}

.border-warning-orange\/5 {
  border-color: #ff8e290d;
}

.border-warning-orange\/50 {
  border-color: #ff8e2980;
}

.border-warning-orange\/55 {
  border-color: #ff8e298c;
}

.border-warning-orange\/60 {
  border-color: #ff8e2999;
}

.border-warning-orange\/65 {
  border-color: #ff8e29a6;
}

.border-warning-orange\/70 {
  border-color: #ff8e29b3;
}

.border-warning-orange\/75 {
  border-color: #ff8e29bf;
}

.border-warning-orange\/80 {
  border-color: #ff8e29cc;
}

.border-warning-orange\/85 {
  border-color: #ff8e29d9;
}

.border-warning-orange\/90 {
  border-color: #ff8e29e6;
}

.border-warning-orange\/95 {
  border-color: #ff8e29f2;
}

.border-warning\/0 {
  border-color: var(--fallback-wa, oklch(var(--wa) / 0));
}

.border-warning\/10 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .1));
}

.border-warning\/100 {
  border-color: var(--fallback-wa, oklch(var(--wa) / 1));
}

.border-warning\/15 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .15));
}

.border-warning\/20 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .2));
}

.border-warning\/25 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .25));
}

.border-warning\/30 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .3));
}

.border-warning\/35 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .35));
}

.border-warning\/40 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .4));
}

.border-warning\/45 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .45));
}

.border-warning\/5 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .05));
}

.border-warning\/50 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .5));
}

.border-warning\/55 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .55));
}

.border-warning\/60 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .6));
}

.border-warning\/65 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .65));
}

.border-warning\/70 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .7));
}

.border-warning\/75 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .75));
}

.border-warning\/80 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .8));
}

.border-warning\/85 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .85));
}

.border-warning\/90 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .9));
}

.border-warning\/95 {
  border-color: var(--fallback-wa, oklch(var(--wa) / .95));
}

.\!bg-gray-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity)) !important;
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.bg-amber-100\/0 {
  background-color: #fef3c700;
}

.bg-amber-100\/10 {
  background-color: #fef3c71a;
}

.bg-amber-100\/100 {
  background-color: #fef3c7;
}

.bg-amber-100\/15 {
  background-color: #fef3c726;
}

.bg-amber-100\/20 {
  background-color: #fef3c733;
}

.bg-amber-100\/25 {
  background-color: #fef3c740;
}

.bg-amber-100\/30 {
  background-color: #fef3c74d;
}

.bg-amber-100\/35 {
  background-color: #fef3c759;
}

.bg-amber-100\/40 {
  background-color: #fef3c766;
}

.bg-amber-100\/45 {
  background-color: #fef3c773;
}

.bg-amber-100\/5 {
  background-color: #fef3c70d;
}

.bg-amber-100\/50 {
  background-color: #fef3c780;
}

.bg-amber-100\/55 {
  background-color: #fef3c78c;
}

.bg-amber-100\/60 {
  background-color: #fef3c799;
}

.bg-amber-100\/65 {
  background-color: #fef3c7a6;
}

.bg-amber-100\/70 {
  background-color: #fef3c7b3;
}

.bg-amber-100\/75 {
  background-color: #fef3c7bf;
}

.bg-amber-100\/80 {
  background-color: #fef3c7cc;
}

.bg-amber-100\/85 {
  background-color: #fef3c7d9;
}

.bg-amber-100\/90 {
  background-color: #fef3c7e6;
}

.bg-amber-100\/95 {
  background-color: #fef3c7f2;
}

.bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}

.bg-amber-300\/0 {
  background-color: #fcd34d00;
}

.bg-amber-300\/10 {
  background-color: #fcd34d1a;
}

.bg-amber-300\/100 {
  background-color: #fcd34d;
}

.bg-amber-300\/15 {
  background-color: #fcd34d26;
}

.bg-amber-300\/20 {
  background-color: #fcd34d33;
}

.bg-amber-300\/25 {
  background-color: #fcd34d40;
}

.bg-amber-300\/30 {
  background-color: #fcd34d4d;
}

.bg-amber-300\/35 {
  background-color: #fcd34d59;
}

.bg-amber-300\/40 {
  background-color: #fcd34d66;
}

.bg-amber-300\/45 {
  background-color: #fcd34d73;
}

.bg-amber-300\/5 {
  background-color: #fcd34d0d;
}

.bg-amber-300\/50 {
  background-color: #fcd34d80;
}

.bg-amber-300\/55 {
  background-color: #fcd34d8c;
}

.bg-amber-300\/60 {
  background-color: #fcd34d99;
}

.bg-amber-300\/65 {
  background-color: #fcd34da6;
}

.bg-amber-300\/70 {
  background-color: #fcd34db3;
}

.bg-amber-300\/75 {
  background-color: #fcd34dbf;
}

.bg-amber-300\/80 {
  background-color: #fcd34dcc;
}

.bg-amber-300\/85 {
  background-color: #fcd34dd9;
}

.bg-amber-300\/90 {
  background-color: #fcd34de6;
}

.bg-amber-300\/95 {
  background-color: #fcd34df2;
}

.bg-base-100 {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(14 14 15 / var(--tw-bg-opacity));
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.bg-cyan-100\/0 {
  background-color: #cffafe00;
}

.bg-cyan-100\/10 {
  background-color: #cffafe1a;
}

.bg-cyan-100\/100 {
  background-color: #cffafe;
}

.bg-cyan-100\/15 {
  background-color: #cffafe26;
}

.bg-cyan-100\/20 {
  background-color: #cffafe33;
}

.bg-cyan-100\/25 {
  background-color: #cffafe40;
}

.bg-cyan-100\/30 {
  background-color: #cffafe4d;
}

.bg-cyan-100\/35 {
  background-color: #cffafe59;
}

.bg-cyan-100\/40 {
  background-color: #cffafe66;
}

.bg-cyan-100\/45 {
  background-color: #cffafe73;
}

.bg-cyan-100\/5 {
  background-color: #cffafe0d;
}

.bg-cyan-100\/50 {
  background-color: #cffafe80;
}

.bg-cyan-100\/55 {
  background-color: #cffafe8c;
}

.bg-cyan-100\/60 {
  background-color: #cffafe99;
}

.bg-cyan-100\/65 {
  background-color: #cffafea6;
}

.bg-cyan-100\/70 {
  background-color: #cffafeb3;
}

.bg-cyan-100\/75 {
  background-color: #cffafebf;
}

.bg-cyan-100\/80 {
  background-color: #cffafecc;
}

.bg-cyan-100\/85 {
  background-color: #cffafed9;
}

.bg-cyan-100\/90 {
  background-color: #cffafee6;
}

.bg-cyan-100\/95 {
  background-color: #cffafef2;
}

.bg-cyan-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

.bg-cyan-300\/0 {
  background-color: #67e8f900;
}

.bg-cyan-300\/10 {
  background-color: #67e8f91a;
}

.bg-cyan-300\/100 {
  background-color: #67e8f9;
}

.bg-cyan-300\/15 {
  background-color: #67e8f926;
}

.bg-cyan-300\/20 {
  background-color: #67e8f933;
}

.bg-cyan-300\/25 {
  background-color: #67e8f940;
}

.bg-cyan-300\/30 {
  background-color: #67e8f94d;
}

.bg-cyan-300\/35 {
  background-color: #67e8f959;
}

.bg-cyan-300\/40 {
  background-color: #67e8f966;
}

.bg-cyan-300\/45 {
  background-color: #67e8f973;
}

.bg-cyan-300\/5 {
  background-color: #67e8f90d;
}

.bg-cyan-300\/50 {
  background-color: #67e8f980;
}

.bg-cyan-300\/55 {
  background-color: #67e8f98c;
}

.bg-cyan-300\/60 {
  background-color: #67e8f999;
}

.bg-cyan-300\/65 {
  background-color: #67e8f9a6;
}

.bg-cyan-300\/70 {
  background-color: #67e8f9b3;
}

.bg-cyan-300\/75 {
  background-color: #67e8f9bf;
}

.bg-cyan-300\/80 {
  background-color: #67e8f9cc;
}

.bg-cyan-300\/85 {
  background-color: #67e8f9d9;
}

.bg-cyan-300\/90 {
  background-color: #67e8f9e6;
}

.bg-cyan-300\/95 {
  background-color: #67e8f9f2;
}

.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.bg-emerald-100\/0 {
  background-color: #d1fae500;
}

.bg-emerald-100\/10 {
  background-color: #d1fae51a;
}

.bg-emerald-100\/100 {
  background-color: #d1fae5;
}

.bg-emerald-100\/15 {
  background-color: #d1fae526;
}

.bg-emerald-100\/20 {
  background-color: #d1fae533;
}

.bg-emerald-100\/25 {
  background-color: #d1fae540;
}

.bg-emerald-100\/30 {
  background-color: #d1fae54d;
}

.bg-emerald-100\/35 {
  background-color: #d1fae559;
}

.bg-emerald-100\/40 {
  background-color: #d1fae566;
}

.bg-emerald-100\/45 {
  background-color: #d1fae573;
}

.bg-emerald-100\/5 {
  background-color: #d1fae50d;
}

.bg-emerald-100\/50 {
  background-color: #d1fae580;
}

.bg-emerald-100\/55 {
  background-color: #d1fae58c;
}

.bg-emerald-100\/60 {
  background-color: #d1fae599;
}

.bg-emerald-100\/65 {
  background-color: #d1fae5a6;
}

.bg-emerald-100\/70 {
  background-color: #d1fae5b3;
}

.bg-emerald-100\/75 {
  background-color: #d1fae5bf;
}

.bg-emerald-100\/80 {
  background-color: #d1fae5cc;
}

.bg-emerald-100\/85 {
  background-color: #d1fae5d9;
}

.bg-emerald-100\/90 {
  background-color: #d1fae5e6;
}

.bg-emerald-100\/95 {
  background-color: #d1fae5f2;
}

.bg-emerald-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

.bg-emerald-300\/0 {
  background-color: #6ee7b700;
}

.bg-emerald-300\/10 {
  background-color: #6ee7b71a;
}

.bg-emerald-300\/100 {
  background-color: #6ee7b7;
}

.bg-emerald-300\/15 {
  background-color: #6ee7b726;
}

.bg-emerald-300\/20 {
  background-color: #6ee7b733;
}

.bg-emerald-300\/25 {
  background-color: #6ee7b740;
}

.bg-emerald-300\/30 {
  background-color: #6ee7b74d;
}

.bg-emerald-300\/35 {
  background-color: #6ee7b759;
}

.bg-emerald-300\/40 {
  background-color: #6ee7b766;
}

.bg-emerald-300\/45 {
  background-color: #6ee7b773;
}

.bg-emerald-300\/5 {
  background-color: #6ee7b70d;
}

.bg-emerald-300\/50 {
  background-color: #6ee7b780;
}

.bg-emerald-300\/55 {
  background-color: #6ee7b78c;
}

.bg-emerald-300\/60 {
  background-color: #6ee7b799;
}

.bg-emerald-300\/65 {
  background-color: #6ee7b7a6;
}

.bg-emerald-300\/70 {
  background-color: #6ee7b7b3;
}

.bg-emerald-300\/75 {
  background-color: #6ee7b7bf;
}

.bg-emerald-300\/80 {
  background-color: #6ee7b7cc;
}

.bg-emerald-300\/85 {
  background-color: #6ee7b7d9;
}

.bg-emerald-300\/90 {
  background-color: #6ee7b7e6;
}

.bg-emerald-300\/95 {
  background-color: #6ee7b7f2;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.bg-indigo-100\/0 {
  background-color: #e0e7ff00;
}

.bg-indigo-100\/10 {
  background-color: #e0e7ff1a;
}

.bg-indigo-100\/100 {
  background-color: #e0e7ff;
}

.bg-indigo-100\/15 {
  background-color: #e0e7ff26;
}

.bg-indigo-100\/20 {
  background-color: #e0e7ff33;
}

.bg-indigo-100\/25 {
  background-color: #e0e7ff40;
}

.bg-indigo-100\/30 {
  background-color: #e0e7ff4d;
}

.bg-indigo-100\/35 {
  background-color: #e0e7ff59;
}

.bg-indigo-100\/40 {
  background-color: #e0e7ff66;
}

.bg-indigo-100\/45 {
  background-color: #e0e7ff73;
}

.bg-indigo-100\/5 {
  background-color: #e0e7ff0d;
}

.bg-indigo-100\/50 {
  background-color: #e0e7ff80;
}

.bg-indigo-100\/55 {
  background-color: #e0e7ff8c;
}

.bg-indigo-100\/60 {
  background-color: #e0e7ff99;
}

.bg-indigo-100\/65 {
  background-color: #e0e7ffa6;
}

.bg-indigo-100\/70 {
  background-color: #e0e7ffb3;
}

.bg-indigo-100\/75 {
  background-color: #e0e7ffbf;
}

.bg-indigo-100\/80 {
  background-color: #e0e7ffcc;
}

.bg-indigo-100\/85 {
  background-color: #e0e7ffd9;
}

.bg-indigo-100\/90 {
  background-color: #e0e7ffe6;
}

.bg-indigo-100\/95 {
  background-color: #e0e7fff2;
}

.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

.bg-indigo-300\/0 {
  background-color: #a5b4fc00;
}

.bg-indigo-300\/10 {
  background-color: #a5b4fc1a;
}

.bg-indigo-300\/100 {
  background-color: #a5b4fc;
}

.bg-indigo-300\/15 {
  background-color: #a5b4fc26;
}

.bg-indigo-300\/20 {
  background-color: #a5b4fc33;
}

.bg-indigo-300\/25 {
  background-color: #a5b4fc40;
}

.bg-indigo-300\/30 {
  background-color: #a5b4fc4d;
}

.bg-indigo-300\/35 {
  background-color: #a5b4fc59;
}

.bg-indigo-300\/40 {
  background-color: #a5b4fc66;
}

.bg-indigo-300\/45 {
  background-color: #a5b4fc73;
}

.bg-indigo-300\/5 {
  background-color: #a5b4fc0d;
}

.bg-indigo-300\/50 {
  background-color: #a5b4fc80;
}

.bg-indigo-300\/55 {
  background-color: #a5b4fc8c;
}

.bg-indigo-300\/60 {
  background-color: #a5b4fc99;
}

.bg-indigo-300\/65 {
  background-color: #a5b4fca6;
}

.bg-indigo-300\/70 {
  background-color: #a5b4fcb3;
}

.bg-indigo-300\/75 {
  background-color: #a5b4fcbf;
}

.bg-indigo-300\/80 {
  background-color: #a5b4fccc;
}

.bg-indigo-300\/85 {
  background-color: #a5b4fcd9;
}

.bg-indigo-300\/90 {
  background-color: #a5b4fce6;
}

.bg-indigo-300\/95 {
  background-color: #a5b4fcf2;
}

.bg-light-background {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.bg-orange-100\/0 {
  background-color: #ffedd500;
}

.bg-orange-100\/10 {
  background-color: #ffedd51a;
}

.bg-orange-100\/100 {
  background-color: #ffedd5;
}

.bg-orange-100\/15 {
  background-color: #ffedd526;
}

.bg-orange-100\/20 {
  background-color: #ffedd533;
}

.bg-orange-100\/25 {
  background-color: #ffedd540;
}

.bg-orange-100\/30 {
  background-color: #ffedd54d;
}

.bg-orange-100\/35 {
  background-color: #ffedd559;
}

.bg-orange-100\/40 {
  background-color: #ffedd566;
}

.bg-orange-100\/45 {
  background-color: #ffedd573;
}

.bg-orange-100\/5 {
  background-color: #ffedd50d;
}

.bg-orange-100\/50 {
  background-color: #ffedd580;
}

.bg-orange-100\/55 {
  background-color: #ffedd58c;
}

.bg-orange-100\/60 {
  background-color: #ffedd599;
}

.bg-orange-100\/65 {
  background-color: #ffedd5a6;
}

.bg-orange-100\/70 {
  background-color: #ffedd5b3;
}

.bg-orange-100\/75 {
  background-color: #ffedd5bf;
}

.bg-orange-100\/80 {
  background-color: #ffedd5cc;
}

.bg-orange-100\/85 {
  background-color: #ffedd5d9;
}

.bg-orange-100\/90 {
  background-color: #ffedd5e6;
}

.bg-orange-100\/95 {
  background-color: #ffedd5f2;
}

.bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}

.bg-orange-300\/0 {
  background-color: #fdba7400;
}

.bg-orange-300\/10 {
  background-color: #fdba741a;
}

.bg-orange-300\/100 {
  background-color: #fdba74;
}

.bg-orange-300\/15 {
  background-color: #fdba7426;
}

.bg-orange-300\/20 {
  background-color: #fdba7433;
}

.bg-orange-300\/25 {
  background-color: #fdba7440;
}

.bg-orange-300\/30 {
  background-color: #fdba744d;
}

.bg-orange-300\/35 {
  background-color: #fdba7459;
}

.bg-orange-300\/40 {
  background-color: #fdba7466;
}

.bg-orange-300\/45 {
  background-color: #fdba7473;
}

.bg-orange-300\/5 {
  background-color: #fdba740d;
}

.bg-orange-300\/50 {
  background-color: #fdba7480;
}

.bg-orange-300\/55 {
  background-color: #fdba748c;
}

.bg-orange-300\/60 {
  background-color: #fdba7499;
}

.bg-orange-300\/65 {
  background-color: #fdba74a6;
}

.bg-orange-300\/70 {
  background-color: #fdba74b3;
}

.bg-orange-300\/75 {
  background-color: #fdba74bf;
}

.bg-orange-300\/80 {
  background-color: #fdba74cc;
}

.bg-orange-300\/85 {
  background-color: #fdba74d9;
}

.bg-orange-300\/90 {
  background-color: #fdba74e6;
}

.bg-orange-300\/95 {
  background-color: #fdba74f2;
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.bg-rose-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}

.bg-rose-100\/0 {
  background-color: #ffe4e600;
}

.bg-rose-100\/10 {
  background-color: #ffe4e61a;
}

.bg-rose-100\/100 {
  background-color: #ffe4e6;
}

.bg-rose-100\/15 {
  background-color: #ffe4e626;
}

.bg-rose-100\/20 {
  background-color: #ffe4e633;
}

.bg-rose-100\/25 {
  background-color: #ffe4e640;
}

.bg-rose-100\/30 {
  background-color: #ffe4e64d;
}

.bg-rose-100\/35 {
  background-color: #ffe4e659;
}

.bg-rose-100\/40 {
  background-color: #ffe4e666;
}

.bg-rose-100\/45 {
  background-color: #ffe4e673;
}

.bg-rose-100\/5 {
  background-color: #ffe4e60d;
}

.bg-rose-100\/50 {
  background-color: #ffe4e680;
}

.bg-rose-100\/55 {
  background-color: #ffe4e68c;
}

.bg-rose-100\/60 {
  background-color: #ffe4e699;
}

.bg-rose-100\/65 {
  background-color: #ffe4e6a6;
}

.bg-rose-100\/70 {
  background-color: #ffe4e6b3;
}

.bg-rose-100\/75 {
  background-color: #ffe4e6bf;
}

.bg-rose-100\/80 {
  background-color: #ffe4e6cc;
}

.bg-rose-100\/85 {
  background-color: #ffe4e6d9;
}

.bg-rose-100\/90 {
  background-color: #ffe4e6e6;
}

.bg-rose-100\/95 {
  background-color: #ffe4e6f2;
}

.bg-rose-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 164 175 / var(--tw-bg-opacity));
}

.bg-rose-300\/0 {
  background-color: #fda4af00;
}

.bg-rose-300\/10 {
  background-color: #fda4af1a;
}

.bg-rose-300\/100 {
  background-color: #fda4af;
}

.bg-rose-300\/15 {
  background-color: #fda4af26;
}

.bg-rose-300\/20 {
  background-color: #fda4af33;
}

.bg-rose-300\/25 {
  background-color: #fda4af40;
}

.bg-rose-300\/30 {
  background-color: #fda4af4d;
}

.bg-rose-300\/35 {
  background-color: #fda4af59;
}

.bg-rose-300\/40 {
  background-color: #fda4af66;
}

.bg-rose-300\/45 {
  background-color: #fda4af73;
}

.bg-rose-300\/5 {
  background-color: #fda4af0d;
}

.bg-rose-300\/50 {
  background-color: #fda4af80;
}

.bg-rose-300\/55 {
  background-color: #fda4af8c;
}

.bg-rose-300\/60 {
  background-color: #fda4af99;
}

.bg-rose-300\/65 {
  background-color: #fda4afa6;
}

.bg-rose-300\/70 {
  background-color: #fda4afb3;
}

.bg-rose-300\/75 {
  background-color: #fda4afbf;
}

.bg-rose-300\/80 {
  background-color: #fda4afcc;
}

.bg-rose-300\/85 {
  background-color: #fda4afd9;
}

.bg-rose-300\/90 {
  background-color: #fda4afe6;
}

.bg-rose-300\/95 {
  background-color: #fda4aff2;
}

.bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.bg-violet-100\/0 {
  background-color: #ede9fe00;
}

.bg-violet-100\/10 {
  background-color: #ede9fe1a;
}

.bg-violet-100\/100 {
  background-color: #ede9fe;
}

.bg-violet-100\/15 {
  background-color: #ede9fe26;
}

.bg-violet-100\/20 {
  background-color: #ede9fe33;
}

.bg-violet-100\/25 {
  background-color: #ede9fe40;
}

.bg-violet-100\/30 {
  background-color: #ede9fe4d;
}

.bg-violet-100\/35 {
  background-color: #ede9fe59;
}

.bg-violet-100\/40 {
  background-color: #ede9fe66;
}

.bg-violet-100\/45 {
  background-color: #ede9fe73;
}

.bg-violet-100\/5 {
  background-color: #ede9fe0d;
}

.bg-violet-100\/50 {
  background-color: #ede9fe80;
}

.bg-violet-100\/55 {
  background-color: #ede9fe8c;
}

.bg-violet-100\/60 {
  background-color: #ede9fe99;
}

.bg-violet-100\/65 {
  background-color: #ede9fea6;
}

.bg-violet-100\/70 {
  background-color: #ede9feb3;
}

.bg-violet-100\/75 {
  background-color: #ede9febf;
}

.bg-violet-100\/80 {
  background-color: #ede9fecc;
}

.bg-violet-100\/85 {
  background-color: #ede9fed9;
}

.bg-violet-100\/90 {
  background-color: #ede9fee6;
}

.bg-violet-100\/95 {
  background-color: #ede9fef2;
}

.bg-violet-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}

.bg-violet-300\/0 {
  background-color: #c4b5fd00;
}

.bg-violet-300\/10 {
  background-color: #c4b5fd1a;
}

.bg-violet-300\/100 {
  background-color: #c4b5fd;
}

.bg-violet-300\/15 {
  background-color: #c4b5fd26;
}

.bg-violet-300\/20 {
  background-color: #c4b5fd33;
}

.bg-violet-300\/25 {
  background-color: #c4b5fd40;
}

.bg-violet-300\/30 {
  background-color: #c4b5fd4d;
}

.bg-violet-300\/35 {
  background-color: #c4b5fd59;
}

.bg-violet-300\/40 {
  background-color: #c4b5fd66;
}

.bg-violet-300\/45 {
  background-color: #c4b5fd73;
}

.bg-violet-300\/5 {
  background-color: #c4b5fd0d;
}

.bg-violet-300\/50 {
  background-color: #c4b5fd80;
}

.bg-violet-300\/55 {
  background-color: #c4b5fd8c;
}

.bg-violet-300\/60 {
  background-color: #c4b5fd99;
}

.bg-violet-300\/65 {
  background-color: #c4b5fda6;
}

.bg-violet-300\/70 {
  background-color: #c4b5fdb3;
}

.bg-violet-300\/75 {
  background-color: #c4b5fdbf;
}

.bg-violet-300\/80 {
  background-color: #c4b5fdcc;
}

.bg-violet-300\/85 {
  background-color: #c4b5fdd9;
}

.bg-violet-300\/90 {
  background-color: #c4b5fde6;
}

.bg-violet-300\/95 {
  background-color: #c4b5fdf2;
}

.bg-warning\/50 {
  background-color: var(--fallback-wa, oklch(var(--wa) / .5));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xxs {
  letter-spacing: .33px;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}

.font-bold {
  font-weight: 700;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-6 {
  line-height: 1.5rem;
}

.text-current {
  color: currentColor;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-green-dark {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-neutral {
  --tw-text-opacity: 1;
  color: var(--fallback-n, oklch(var(--n) / var(--tw-text-opacity)));
}

.text-primary {
  --tw-text-opacity: 1;
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(255 0 28 / var(--tw-text-opacity));
}

.text-red-dark {
  --tw-text-opacity: 1;
  color: rgb(163 0 18 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 14 15 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: Neue Haas Grotesk Display Pro;
  src: url("nhaasgroteskdspro-regular.03cbddf8.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Grotesk Display Pro;
  src: url("nhaasgroteskdspro-regular-italic.30cfaf4c.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Neue Haas Grotesk Display Pro;
  src: url("nhaasgroteskdspro-bold.89835852.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Grotesk Display Pro;
  src: url("nhaasgroteskdspro-bold-italic.e5907ae2.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Neue Haas Grotesk Display Pro;
  src: url("nhaasgroteskdspro-medium.6d91c254.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Neue Haas Grotesk Display Pro;
  src: url("nhaasgroteskdspro-medium-italic.cf4c2d1a.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Neue Haas Grotesk Display Pro;
  src: url("nhaasgroteskdspro-extralight.cfca8d14.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

a.block-underline:hover li {
  text-decoration: underline;
}

:root {
  --ascii-art-width: 800px;
  --maza-logo-aspect-ratio: 840 / 312.74;
  --maza-logo-width: 100px;
}

.ascii-art-container {
  width: var(--ascii-art-width);
  padding: 1rem;
}

.ascii-art {
  font-size: calc(.005 * var(--ascii-art-width));
  width: max-content;
  margin: 0 auto;
}

.maza-logo-cs {
  width: var(--maza-logo-width);
  aspect-ratio: var(--maza-logo-aspect-ratio);
  background: url("MazaCodespacesLogo.613f134e.svg") 0 0 / cover no-repeat;
}

.maza-logo-dev {
  width: var(--maza-logo-width);
  aspect-ratio: var(--maza-logo-aspect-ratio);
  background: url("MazaDevLogo.15a2d009.svg") 0 0 / cover no-repeat;
}

.maza-logo {
  aspect-ratio: var(--maza-logo-aspect-ratio);
  background: url("MazaLogo.0e406b95.svg") 0 0 / cover no-repeat;
  width: 100px;
}

#header-dropdown.hide {
  opacity: 0;
  background-color: #0000;
  transition-duration: 75ms;
  transform: scale(.95);
}

#header-dropdown {
  opacity: 1;
  background-color: #f9fafb;
  transition-property: opacity, scale;
  transition-duration: .1s;
  transform: scale(1);
}

.error {
  color: #f00c;
  background-color: #ff00001a;
  border-color: #f003;
}

.warning {
  color: #ff7f00cc;
  background-color: #ff7f001a;
  border-color: #ff7f0033;
}

.warning:hover {
  color: #fff;
  background-color: red;
}

.info {
  color: #00fc;
  background-color: #0000ff1a;
  border-color: #00f3;
}

.info:hover {
  color: #fff;
  background-color: #00f;
}

.success {
  color: #007f00cc;
  background-color: #007f001a;
  border-color: #007f0033;
}

.aa-Form {
  border-color: #d3d3d3;
}

@media (min-width: 768px) {
  .md\:timeline-horizontal.timeline-compact {
    --timeline-row-start: 0;
  }

  .md\:timeline-horizontal.timeline-compact .timeline-start {
    grid-area: 3 / 1 / 4 / 4;
    place-self: flex-start center;
    margin: .25rem;
  }

  .md\:timeline-horizontal.timeline-compact li:has(.timeline-start) .timeline-end {
    grid-row-start: auto;
    grid-column-start: none;
  }

  .md\:timeline-horizontal {
    flex-direction: row;
  }

  .md\:timeline-horizontal > li > hr {
    width: 100%;
  }

  :where(.md\:timeline-horizontal > li) {
    align-items: center;
  }

  :where(.md\:timeline-horizontal > li > hr):first-child {
    grid-row-start: 2;
    grid-column-start: 1;
  }

  :where(.md\:timeline-horizontal > li > hr):last-child {
    grid-area: 2 / 3 / auto / none;
  }

  .md\:timeline-horizontal .timeline-start {
    grid-area: 1 / 1 / 2 / 4;
    place-self: flex-end center;
  }

  .md\:timeline-horizontal .timeline-end {
    grid-area: 3 / 1 / 4 / 4;
    place-self: flex-start center;
  }

  .md\:timeline-horizontal:where(.timeline-snap-icon) > li {
    --timeline-col-start: .5rem;
    --timeline-row-start: minmax(0, 1fr);
  }

  .md\:timeline-horizontal > li > hr {
    height: .25rem;
  }

  :where(.md\:timeline-horizontal:has(.timeline-middle) > li > hr):first-child {
    border-start-start-radius: 0;
    border-start-end-radius: var(--rounded-badge, 1.9rem);
    border-end-end-radius: var(--rounded-badge, 1.9rem);
    border-end-start-radius: 0;
  }

  :where(.md\:timeline-horizontal:has(.timeline-middle) > li > hr):last-child, :where(.md\:timeline-horizontal:not(:has(.timeline-middle)) :first-child > hr:last-child) {
    border-start-start-radius: var(--rounded-badge, 1.9rem);
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    border-end-start-radius: var(--rounded-badge, 1.9rem);
  }

  :where(.md\:timeline-horizontal:not(:has(.timeline-middle)) :last-child > hr:first-child) {
    border-start-start-radius: 0;
    border-start-end-radius: var(--rounded-badge, 1.9rem);
    border-end-end-radius: var(--rounded-badge, 1.9rem);
    border-end-start-radius: 0;
  }
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.hover\:bg-primary-content\/50:hover {
  background-color: var(--fallback-pc, oklch(var(--pc) / .5));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.hover\:text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-gray-800:focus {
  --tw-ring-offset-color: #1f2937;
}

.focus-visible\:outline-offset-0:focus-visible {
  outline-offset: 0px;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:border-gray-300:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.disabled\:bg-gray-300:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.disabled\:text-gray-600:disabled {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:w-80 {
    width: 20rem;
  }

  .sm\:flex-auto {
    flex: auto;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:pl-0 {
    padding-left: 0;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:leading-6 {
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:sticky {
    position: sticky;
  }

  .md\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:min-h-0 {
    min-height: 0;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:max-w-sm {
    max-width: 24rem;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:basis-1\/3 {
    flex-basis: 33.3333%;
  }

  .md\:basis-2\/3 {
    flex-basis: 66.6667%;
  }

  .md\:flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:min-w-\[190px\] {
    min-width: 190px;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .xl\:w-72 {
    width: 18rem;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:justify-self-end {
    justify-self: end;
  }
}

.\[\&\>\*\:nth-child\(even\)\]\:bg-white > :nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.\[\&\>\*\:nth-child\(odd\)\]\:bg-gray-100 > :nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

/*# sourceMappingURL=Admin.css.map */
