@config "../../tailwind.admin.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./fonts/nhaasgroteskdspro-regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./fonts/nhaasgroteskdspro-regular-italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./fonts/nhaasgroteskdspro-bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./fonts/nhaasgroteskdspro-bold-italic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./fonts/nhaasgroteskdspro-medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./fonts/nhaasgroteskdspro-medium-italic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./fonts/nhaasgroteskdspro-extralight.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

a.block-underline:hover li {
  text-decoration: underline;
}

:root {
  --ascii-art-width: 800px;
  --maza-logo-aspect-ratio: 840 / 312.74;
  --maza-logo-width: 100px;
}
.ascii-art-container {
  width: var(--ascii-art-width);
  padding: 1rem;
}

.ascii-art {
  font-size: calc(0.005 * var(--ascii-art-width));
  width: max-content;
  margin: 0 auto;
}

.maza-logo-cs {
  background: url(./MazaCodespacesLogo.svg) no-repeat;
  background-size: cover;
  width: var(--maza-logo-width);
  aspect-ratio: var(--maza-logo-aspect-ratio);
}

.maza-logo-dev {
  background: url(./MazaDevLogo.svg) no-repeat;
  background-size: cover;
  width: var(--maza-logo-width);
  aspect-ratio: var(--maza-logo-aspect-ratio);
}

.maza-logo {
  background: url(./MazaLogo.svg) no-repeat;
  background-size: cover;
  width: 100px;
  aspect-ratio: var(--maza-logo-aspect-ratio);
}

#header-dropdown.hide {
  background-color: transparent;
  transition-duration: 75ms;
  opacity: 0;
  transform: scale(0.95);
}

#header-dropdown {
  background-color: theme("colors.gray.50");
  transition-property: opacity, scale;
  transition-duration: 100ms;
  transform: scale(1);
  opacity: 1;
}

/* Firetruck red */
.error {
  color: rgba(255, 0, 0, 0.8);
  background-color: rgba(255, 0, 0, 0.1);
  border-color: rgba(255, 0, 0, 0.2);
}

/* Burnt Orange */
.warning {
  color: rgba(255, 127, 0, 0.8);
  background-color: rgba(255, 127, 0, 0.1);
  border-color: rgba(255, 127, 0, 0.2);
}
.warning:hover {
  background-color: red;
  color: white;
}

/* Blue */
.info {
  color: rgba(0, 0, 255, 0.8);
  background-color: rgba(0, 0, 255, 0.1);
  border-color: rgba(0, 0, 255, 0.2);
}

.info:hover {
  background-color: blue;
  color: white;
}

/* Dark Green */
.success {
  color: rgba(0, 127, 0, 0.8);
  background-color: rgba(0, 127, 0, 0.1);
  border-color: rgba(0, 127, 0, 0.2);
}

.aa-Form {
  border-color: lightgray;
}
